import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFormContext, Controller } from 'react-hook-form';
import ReactFlagsSelect from 'react-flags-select';
import { memo, useEffect, useState } from 'react';
import {
  Autocomplete,
  Divider,
  Fab,
  InputAdornment,
  InputLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { constants, formatter } from '../../../../constants';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { v4 as uuidv4 } from 'uuid';
import Button from '@mui/material/Button';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatPhoneNumber } from 'react-phone-number-input';
import ModalSubscriptionsPaywalls from 'app/main/subscriptionManagement/ModalSubscriptionsPayWalls';
import store from 'app/store';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Country, State } from 'country-state-city';
const phoneregex = RegExp('[0-9]');

function CreateCustomers(props) {
  const { id, jsonGetById, prop_data, setSelectedCustomer, setCustomerId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem('token');
  const methods = useFormContext();
  const { watch, control, formState, setValue, setError, getValues, reset } = methods;
  const { errors } = formState;
  const [contact, setcontact] = useState(['contact-0']);
  const [disabled, setDisabled] = useState(false);
  const [fakephone, setfakephone] = useState('');
  const [subscriptionsPaywalls, setSubscriptionsPaywalls] = useState(false);
  const [inputFields, setInputFields] = useState([
    { id: uuidv4(), title: '', name: '', email: '', phone: '' },
  ]);
  const [invoiceInputFields, setInvoiceInputFields] = useState([{ id: uuidv4(), email: '' }]);
  const paywallSubscriptions = () => {
    setSubscriptionsPaywalls(true);
  };
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    isoCode: 'US',
    name: 'United States',
  });

  useEffect(() => {
    // Inicializamos los países que necesitamos (US y MX)
    const availableCountries = [
      Country.getCountryByCode('US'),
      Country.getCountryByCode('MX'),
    ].filter(Boolean);

    setCountries(availableCountries);
  }, []);

  // Actualizar estados cuando cambie el país
  useEffect(() => {
    if (selectedCountry) {
      const statesList = State.getStatesOfCountry(selectedCountry.isoCode);
      setStates(statesList);
    }
  }, [selectedCountry]);
  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }

      setInputFields([...inputFields, { id: uuidv4(), title: '', name: '', email: '', phone: '' }]);
      setValue('contact', inputFields);
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleChangeInputInvoice = (id, event) => {
    const newInvoiceInputFields = invoiceInputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      setInvoiceInputFields([...invoiceInputFields, { id: uuidv4(), email: '' }]);
      setValue('invoice_contacts', invoiceInputFields);
      return i;
    });

    setInvoiceInputFields(newInvoiceInputFields);
  };

  const _setError = () => {
    setError('customer_name');
  };
  const handleAddFields = () => {
    setInputFields([...inputFields, { id: uuidv4(), title: '', name: '', email: '', phone: '' }]);
    setValue('contact', inputFields);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    setValue('contact', values);
  };

  const handleAddFieldsInvoice = () => {
    setInvoiceInputFields([...invoiceInputFields, { id: uuidv4(), email: '' }]);
    setValue('invoice_contacts', invoiceInputFields);
  };

  const handleRemoveFieldsInvoice = (id) => {
    const values = [...invoiceInputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInvoiceInputFields(values);
    setValue('invoice_contacts', values);
  };
  const getDefaultCountry = (countryCode, countryName) => {
    const usCountry = Country.getCountryByCode('US');
    const mxCountry = Country.getCountryByCode('MX');

    // Si no hay país ni código, retorna US
    if (!countryCode && !countryName) {
      return usCountry;
    }

    // Si hay código de país, busca por código
    if (countryCode) {
      if (countryCode === '52') return mxCountry;
      if (countryCode === '1') return usCountry;
    }

    // Si hay nombre de país, busca por nombre
    if (countryName) {
      const foundCountry = [usCountry, mxCountry].find(
        (country) =>
          country.name.toLowerCase() === countryName?.toLowerCase() ||
          (countryName === 'Mexico' && country.name === 'México')
      );
      return foundCountry || usCountry;
    }

    // Por defecto retorna US
    return usCountry;
  };

  useEffect(async () => {
    if (props.jsonGetById.length > 0) {
      const {
        customers_id,
        customer_name,
        customer_email,
        location_id,
        customer_phone,
        contact,
        invoice_contacts,
        customer_website,
        customer_notes,
        customer_status,
        term,
        credit_limits,
        country_code,
        l_address,
        l_city,
        l_state,
        l_country,
        l_zip,
        title,
        name,
        email,
        phone,
      } = props.jsonGetById[0];

      // Nueva lógica para la validación de país y estado
      let countryObject;
      if (l_state && !country_code) {
        // Si hay estado pero no hay país, establecer USA por defecto
        countryObject = getDefaultCountry('1', 'United States');
        setSelectedCountry(countryObject);
        setValue('l_country', countryObject);
      } else if (country_code || l_country) {
        console.log('country_code', country_code);
        // Si hay país, usar la lógica original
        countryObject = getDefaultCountry(null, l_country);
        setSelectedCountry(countryObject);
        setValue('l_country', countryObject);
      } else {
        // Si no hay ni país ni estado, dejar ambos vacíos
        setSelectedCountry(null);
        setValue('l_country', null);
      }

      // Manejar estados solo si hay un país seleccionado
      if (countryObject) {
        const countryStates = State.getStatesOfCountry(countryObject.isoCode);
        setStates(countryStates);

        if (l_state) {
          const stateObject = countryStates.find(
            (state) =>
              state.name.toLowerCase() === l_state.toLowerCase() ||
              state.isoCode.toLowerCase() === l_state.toLowerCase()
          );

          if (stateObject) {
            setValue('l_state', stateObject);
          }
        }
      } else {
        setStates([]);
        setValue('l_state', null);
      }

      setfakephone(customer_phone);
      setValue('customer_name', customer_name);
      setValue('customer_email', customer_email);
      setValue('location_id', location_id);

      const newphone = formatPhoneNumber(`${country_code}${customer_phone}`);
      setValue('customer_phone', {
        phone: `${country_code ? country_code : '1'}${customer_phone}`,
        countryCode: `${country_code || '1'}`,
        country: country_code
          ? country_code === '1'
            ? 'us'
            : country_code === '52'
            ? 'mx'
            : country_code === '57'
            ? 'co'
            : 'us'
          : 'us',
      });
      setValue('customer_website', customer_website);
      setValue('customer_notes', customer_notes);
      setValue('customer_status', customer_status);
      setValue('l_address', l_address);
      setValue('l_city', l_city);
      setValue('l_zip', l_zip);
      setValue('term', term);
      const credit_format = formatter.format(credit_limits);
      setValue('credit_limits', credit_format);

      if (contact && contact.length >= 1) {
        const formattedContacts = contact.map((contact) => ({
          ...contact,
          phone: {
            phone: `${contact.country_code ? contact.country_code : '1'}${contact.phone}`,
            countryCode: `${contact.country_code || '1'}`,
            country: contact.country_code
              ? contact.country_code === '1'
                ? 'us'
                : contact.country_code === '52'
                ? 'mx'
                : 'us'
              : 'us',
          },
        }));

        setInputFields(formattedContacts);
        setValue('contact', formattedContacts);
      }

      if (invoice_contacts && invoice_contacts.length >= 1) {
        setInvoiceInputFields(invoice_contacts);
        setValue('invoice_contacts', invoice_contacts);
      }
    }
  }, []);
  <Controller
    name='country'
    control={control}
    render={({ field: { onChange, value } }) => (
      <Autocomplete
        className='mt-8 mb-16 mx-4'
        options={countries}
        value={value}
        getOptionLabel={(option) => option?.name || ''}
        onChange={(event, newValue) => {
          console.log('newValue', newValue);
          onChange(newValue);
          setSelectedCountry(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label='Country' variant='outlined' fullWidth />
        )}
      />
    )}
  />;

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  async function handleUpdateCustomer() {
    try {
      var newValues = getValues();
      console.log(newValues);

      // Format contact phone numbers and structure
      if (newValues.contact && Array.isArray(newValues.contact)) {
        newValues.contact = newValues.contact.map((contact) => {
          let phoneNumber = '';
          let countryCode = contact.country_code || '';

          if (contact.phone) {
            if (typeof contact.phone === 'object') {
              // Si es un objeto complejo (del GET inicial)
              phoneNumber = contact.phone.phone?.replace(/[^\d]/g, '') || '';
              countryCode = contact.phone.countryCode?.replace(/^\+/, '') || countryCode;
            } else {
              // Si es string (de la forma)
              phoneNumber = contact.phone.replace(/[^\d]/g, '');
            }

            // Remover el código de país si está presente al inicio del número
            if (countryCode && phoneNumber.startsWith(countryCode)) {
              phoneNumber = phoneNumber.slice(countryCode.length);
            }
          }

          return {
            id: contact.id,
            title: contact.title || '',
            name: contact.name || '',
            email: contact.email || '',
            phone: phoneNumber,
            country_code: countryCode,
          };
        });
      }

      // Format customer phone
      if (newValues.customer_phone) {
        let customerPhone = '';
        let countryCode = newValues.country_code || '';

        if (typeof newValues.customer_phone === 'object') {
          // Si viene como objeto (del GET inicial)
          customerPhone = newValues.customer_phone.phone?.replace(/[^\d]/g, '') || '';
          countryCode = newValues.customer_phone.countryCode?.replace(/^\+/, '') || countryCode;
        } else {
          // Si viene como string (de la forma)
          customerPhone = newValues.customer_phone.replace(/[^\d]/g, '');
        }

        // Si el número incluye el código de país, removerlo
        if (customerPhone.startsWith(countryCode)) {
          customerPhone = customerPhone.slice(countryCode.length);
        }

        newValues.customer_phone = customerPhone;
        newValues.country_code = countryCode;
      }

      // Simplify country structure
      if (newValues.l_country && typeof newValues.l_country === 'object') {
        newValues.l_country = newValues.l_country.name;
      }
      // simplify state structure
      if (newValues.l_state && typeof newValues.l_state === 'object') {
        newValues.l_state = newValues.l_state.name;
      }

      const response = await fetch(`${constants.URLLOCAL}/customers/update-customer/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          token: `${token}`,
        },
        body: JSON.stringify(newValues),
      });

      const res = await response.json();

      if (res?.status === 'incomplete_expired') {
        _showMessage(res.message, 'error');
        paywallSubscriptions();
        return;
      }

      if (res.error) {
        _showMessage(
          `Failed to update customer ${getValues(
            'customer_name'
          )}. Please check your input and try again.`,
          'error'
        );
        setDisabled(false);
        return;
      }

      if (res.message) {
        _showMessage(res.message, 'success');
        setDisabled(false);
        return;
      }

      _showMessage(
        `The customer with name ${getValues('customer_name')} has been updated correctly!`,
        'success'
      );

      setSelectedCustomer(transformedValues);
      setDisabled(false);
      _setError();
      props.closeIt();
      // prop_data ? null : history.push('/customers');
    } catch (error) {
      console.log(error);
      _showMessage(
        `An unexpected error occurred while updating the customer. Please try again.`,
        'error'
      );
      setDisabled(false);
    }
  }

  // esta es para el botton de crear customer de abajo
  async function handleSaveCustomer() {
    try {
      var newValues = getValues();

      if (newValues.customer_phone) {
        const mainPhone = newValues.customer_phone;
        (newValues.customer_phone = mainPhone.phone.substring(mainPhone.countryCode.length)),
          (newValues.country_code = mainPhone.countryCode);

        if (newValues.contact && newValues.contact.length > 0) {
          newValues.contact = newValues.contact.map((contact) => {
            if (contact.phone) {
              return {
                id: contact.id,
                title: contact.title,
                name: contact.name,
                email: contact.email,
                phone: contact.phone.phone.substring(contact.phone.countryCode.length),
                country_code: contact.phone.countryCode,
              };
            }
            return contact;
          });
        }
      } else {
        newValues.customer_phone = '';
      }
      const transformedValues = {
        ...newValues,
        l_country: newValues.l_country?.name || '',
        l_state: newValues.l_state?.name || '',
      };
      if (newValues.l_country) {
        newValues.l_country = newValues.l_country.name;
      }
      if (newValues.l_state) {
        newValues.l_state = newValues.l_state.name;
      }

      setDisabled(true);
      const response = await fetch(
        `${constants.URLLOCAL}/customers/new${prop_data ? '/' + prop_data?.compacctnum : ''}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
          body: JSON.stringify(newValues), // body data type must match "Content-Type" header
        }
      );
      const res = await response.json();

      if (res?.status === 'incomplete_expired') {
        _showMessage(res.message, 'error');
        paywallSubscriptions();
        return;
      }

      if (res.error) {
        _showMessage(
          `Failed to update customer ${getValues(
            'customer_name'
          )}. Please check your input and try again.`,
          'error'
        );
        setDisabled(false);
        return;
      }

      console.log('RES =>> ', res);

      if (res.message) {
        _showMessage(res.message, 'error');
        setDisabled(false);
        return;
      }

      _showMessage(
        `The customers with name ${getValues('customer_name')} has been created correctly!`,
        'success'
      );
      setSelectedCustomer(transformedValues);
      setCustomerId(res.user_id);

      setDisabled(false);
      _setError();
      // props.refreshCustomers()
      props.closeIt();
      // prop_data ? null : history.push('/customers');
    } catch (error) {
      console.log(error);
      _showMessage(
        `An unexpected error occurred while updating the customer. Please try again.`,
        'error'
      );
      setDisabled(false);
    }
  }

  return (
    <>
      {subscriptionsPaywalls ? (
        <ModalSubscriptionsPaywalls
          paywallSubscriptions={paywallSubscriptions}
          open={subscriptionsPaywalls}
          setSubscriptionsPaywalls={setSubscriptionsPaywalls}
          compacctnum={
            prop_data?.compacctnum
              ? prop_data?.compacctnum
              : store.getState().loadz_auth.AuthReducer.user.company.compacctnum
          }
        ></ModalSubscriptionsPaywalls>
      ) : null}

      <div className='mt-16'>
        <div className='flex mt-1 mb-1  mx-2 new-customers new'>
          <Controller
            name='customer_name'
            control={control}
            render={({ field }) => (
              <Tooltip title='' placement='right-start'>
                <TextField
                  {...field}
                  className='mt-8 mb-16 mx-2'
                  helperText={!!errors.customer_name ? 'Please fill with customer name' : ''}
                  label='Customer Name'
                  autoFocus
                  id='customer_name'
                  variant='outlined'
                  fullWidth
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: '#fff',
                      border: '1px solid #e2e1e5',
                      padding: '6px 9px',
                      height: '43px',
                    },
                  }}
                />
              </Tooltip>
            )}
          />

          <Controller
            name='customer_email'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-2 '
                label='Customer Email'
                id='customer_email'
                variant='outlined'
                type={'email'}
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: '#fff',
                    border: '1px solid #e2e1e5',
                    padding: '6px 9px',
                    height: '43px',
                  },
                }}
              />
            )}
          />
          <Controller
            name='customer_phone'
            control={control}
            render={({ field: { onChange, value } }) => (
              <PhoneInput
                country={'us'}
                onlyCountries={['us', 'mx', 'co']}
                id='customer_phone'
                value={value?.phone || ''} // Modificado para manejar el nuevo formato
                onChange={(phone, data) => {
                  onChange({
                    phone: phone,
                    countryCode: data.dialCode,
                    country: data.countryCode,
                    format: phone,
                  });
                }}
                containerStyle={{
                  height: '40px',
                  marginTop: '1.0rem',
                  marginBottom: '4rem',
                  marginLeft: '0.5rem',
                  marginRight: '0.5rem',
                }}
                inputStyle={{
                  height: '40px',
                  width: '100%',
                  paddingLeft: '48px !important',
                }}
                countryCodeEditable={false}
                labels={{ us: 'United States', mx: 'Mexico', co: 'Colombia' }}
                inputProps={{
                  required: true,
                  autoFocus: false,
                }}
              />
            )}
          />
        </div>

        <div className='pb-12'>
          <Typography variant='h6' gutterBottom component='div'>
            Address
          </Typography>
        </div>
        {/* <Controller
name="location_id" 
control={control}
render={({ field: { onChange, value } }) => (
<Autocomplete
className="mt-8 mb-16 mx-2"
multiple
freeSolo
// value={value}
// getOptionDisabled={(options) => (value ? value.length == 1 ? true : false : null)}
options={loca}
onChange={(event, newValue) => {
setCreateLocation(false)
newValue.map(r => {
if (r.value == 0) {
  setCreateLocation(true)
}

// }else{
//   locationsOrigin.forEach(loc => {
//     if(loc.l_id === r.value){
//       console.log(loc)
//       setLocationQuarry({
//         name: loc.l_name,
//         lat: loc.l_latitude,
//         lng: loc.l_longitude
//       })
//       console.log(locationQuarry)
//     }
//   })

// }
})
onChange(newValue);
}}
fullWidth

renderInput={(params) => (
<TextField
{...params}
id={loca}
placeholder="Select location"
label="Location"
variant="outlined"
InputLabelProps={{
  shrink: true,
}}
// error={value.length == 0 ? true : false}
// helperText={value.length == 0 ? 'Please select quarry pit for this job' : ''}
/>
)}
limitTags={1}
/>
)}
/> */}

        <div className='flex new-customers-address mb-2  new'>
          <Controller
            name='l_country'
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className='mt-8 mb-16 mx-4'>
                <ReactFlagsSelect
                  selected={value?.isoCode || 'US'} // Default to US if no value
                  onSelect={(code) => {
                    const selectedCountry = {
                      isoCode: code,
                      name: new Intl.DisplayNames(['en'], {
                        type: 'region',
                      }).of(code),
                    };

                    setSelectedCountry(selectedCountry);
                    onChange(selectedCountry);

                    const newStates = code ? State.getStatesOfCountry(code) : [];
                    setStates(newStates);
                    setValue('l_state', null);
                  }}
                  searchable
                  searchPlaceholder='Search countries'
                  placeholder='Select Country'
                  className='h-[43px] min-w-[200px]'
                  countries={['US', 'MX']} // Limitar a solo US y MX
                />
              </div>
            )}
          />

          <Controller
            name='l_address'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-4'
                id='l_address'
                label={selectedCountry?.isoCode === 'MX' ? 'Dirección' : 'Address'}
                variant='outlined'
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: '#fff',
                    border: '1px solid #e2e1e5',
                    padding: '6px 9px',
                    height: '43px',
                  },
                }}
                // disabled={true}
              />
            )}
          />

          <Controller
            name='l_city'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-4'
                id='l_city'
                placeholder='City'
                label={selectedCountry?.isoCode === 'MX' ? 'Ciudad' : 'City'}
                variant='outlined'
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: '#fff',
                    border: '1px solid #e2e1e5',
                    padding: '6px 9px',
                    height: '43px',
                  },
                }}
              />
            )}
          />

          <Controller
            name='l_state'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '2px',
                  },
                  '& .MuiAutocomplete-input': {
                    padding: '5px 4px !important',
                    width: '100%',
                  },
                  '& .MuiInputBase-root': {
                    height: '43px',
                    minWidth: '200px',
                  },
                }}
                style={{ height: '25px' }}
                className='mt-8 mb-16 mx-4 h-2'
                options={states}
                value={value}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={`Select ${
                      selectedCountry?.isoCode === 'MX' ? 'Provincia' : 'State'
                    }`}
                    className=' mb-16 mx-4'
                    variant='outlined'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        background: '#fff',
                        border: '1px solid #e2e1e5',
                        padding: '6px 9px',
                        height: '43px',
                      },
                    }}
                  />
                )}
              />
            )}
          />

          <Controller
            name='l_zip'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-4'
                id='l_zip'
                label={selectedCountry?.isoCode === 'MX' ? 'Código Postal' : 'ZIP Code'}
                variant='outlined'
                fullWidth
                type={'name'}
                onInput={(e) => {
                  if (!/^\d*$/.test(e.target.value)) {
                    e.target.value = e.target.value.replace(/\D/g, '');
                  }
                }}
                inputProps={{
                  maxLength: selectedCountry?.isoCode === 'MX' ? 6 : 5,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: '#fff',
                    border: '1px solid #e2e1e5',
                    padding: '6px 9px',
                    height: '43px',
                  },
                }}
              />
            )}
          />
        </div>

        <div className='pb-12'>
          <Typography variant='h6' gutterBottom component='div'>
            Contact
          </Typography>
        </div>

        <Controller
          name='contact'
          control={control}
          render={({ field }) => (
            <>
              {inputFields.map((inputField) => (
                <div key={inputField.id} className='flex mx-6'>
                  <TextField
                    {...field}
                    name='title'
                    id='title'
                    className='mt-8 mb-16 mx-2'
                    label='Title'
                    type={'text'}
                    variant='outlined'
                    value={inputField.title}
                    onChange={(event) => handleChangeInput(inputField.id, event)}
                    fullWidth
                    InputProps={{
                      shrink: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        background: '#fff',
                        border: '1px solid #e2e1e5',
                        padding: '6px 9px',
                        height: '43px',
                      },
                    }}
                  />

                  <TextField
                    className='mt-8 mb-16 mx-2'
                    label='Name'
                    type={'text'}
                    name='name'
                    variant='outlined'
                    value={inputField.name}
                    onChange={(event) => handleChangeInput(inputField.id, event)}
                    fullWidth
                    InputProps={{
                      shrink: true,
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        background: '#fff',
                        border: '1px solid #e2e1e5',
                        padding: '6px 9px',
                        height: '43px',
                      },
                    }}
                  />

                  <TextField
                    className='mt-8 mb-16 mx-2 email'
                    label='Email'
                    name='email'
                    type={'email'}
                    variant='outlined'
                    value={inputField.email}
                    onChange={(event) => handleChangeInput(inputField.id, event)}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'></InputAdornment>,
                      shrink: true,
                    }}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        background: '#fff',
                        border: '1px solid #e2e1e5',
                        padding: '6px 9px',
                        height: '43px',
                      },
                    }}
                  />
                  <PhoneInput
                    name='phone'
                    country={'us'}
                    onlyCountries={['us', 'mx']}
                    value={inputField.phone?.phone || inputField.phone || ''} // Modificado para manejar el nuevo formato
                    onChange={(phone, data) => {
                      // recortar el phone mediante el dialCode

                      const simulatedEvent = {
                        target: {
                          name: 'phone',
                          value: {
                            phone: phone,
                            countryCode: data.dialCode,
                            country: data.countryCode,
                          },
                        },
                      };
                      handleChangeInput(inputField.id, simulatedEvent);
                    }}
                    containerClass='mt-8 mb-16 mx-2'
                    inputStyle={{
                      width: '100%',
                      height: '44px',
                      fontSize: '16px',
                      borderRadius: '4px',
                    }}
                    buttonStyle={{
                      borderRadius: '4px 0 0 4px',
                    }}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: false,
                    }}
                    specialLabel='Phone'
                    enableSearch={false}
                    countryCodeEditable={false}
                  />

                  <div className='mt-8 mb-16 mx-2 addbtn' style={{ alignSelf: 'center' }}>
                    <InputAdornment position='start'>
                      <Fab color='primary' size='small' onClick={handleAddFields}>
                        <Tooltip title='Add Contact'>
                          <AddIcon />
                        </Tooltip>
                      </Fab>
                    </InputAdornment>
                  </div>

                  <div className='mt-8 mb-16 mx-2 remove-btn' style={{ alignSelf: 'center' }}>
                    <InputAdornment position='start'>
                      <Fab
                        color='danger'
                        size='small'
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                      >
                        <Tooltip title='Remove Contact'>
                          <RemoveIcon />
                        </Tooltip>
                      </Fab>
                    </InputAdornment>
                  </div>
                </div>
              ))}
            </>
          )}
        />

        {/* <div className='pb-12'>
<Typography
variant="h6"
gutterBottom
component="div"
>
Invoice Contacts
</Typography>
</div>

<Controller
name="invoice_contacts"
control={control}

render={({ field }) => (
<>
{invoiceInputFields.map((inputField) => (
<div
  key={inputField.id}
  className="flex mx-6"
>
  <TextField
    {...field}
    name="title"
    id="title"
    className="mt-8 mb-16 mx-2"
    label="Title"
    type={'text'}
    variant="outlined"
    value={inputField.title}
    onChange={(event) => handleChangeInputInvoice(inputField.id, event)}
    fullWidth
    InputProps={{
      shrink: true,
    }}
  />

  <TextField
    className="mt-8 mb-16 mx-2"
    label="Name"
    type={'text'}
    name="name"
    variant="outlined"
    value={inputField.name}
    onChange={(event) => handleChangeInputInvoice(inputField.id, event)}
    fullWidth
    InputProps={{
      shrink: true,
    }}
  />

  <TextField
    className="mt-8 mb-16 mx-2 email"
    label="Email"
    name="email"
    type={'email'}
    variant="outlined"
    value={inputField.email}
    onChange={(event) => handleChangeInputInvoice(inputField.id, event)}
    InputProps={{
      startAdornment: <InputAdornment position="start"></InputAdornment>,
      shrink: true,
    }}
    fullWidth
  />

  <TextField
    className="mt-8 mb-16 mx-2"
    label="Phone"
    name="phone"
    type={'name'}
    variant="outlined"
    value={inputField.phone}
    InputProps={{
      startAdornment: <InputAdornment position="start"></InputAdornment>,
      shrink: true,
    }}
    onInput={(e) => {
      if (!phoneregex.test(e.target.value.slice(-1))) {
        e.target.value = e.target.value.slice(0, -1);
      }
      const newphone = formatPhoneNumber('+1' + e.target.value);

      if (newphone.length > 1) {
        e.target.value = newphone;
      }
      handleChangeInputInvoice(inputField.id, e);
    }}
    inputProps={{ maxLength: 10 }}
    fullWidth
  />

  <div
    className="mt-8 mb-16 mx-2 addbtn"
    style={{ alignSelf: 'center' }}
  >
    <InputAdornment position="start">
      <Fab
        color="primary"
        size="small"
        onClick={handleAddFieldsInvoice}
      >
        <Tooltip title="Add Contact">
          <AddIcon />
        </Tooltip>
      </Fab>
    </InputAdornment>
  </div>

  <div
    className="mt-8 mb-16 mx-2 remove-btn"
    style={{ alignSelf: 'center' }}
  >
    <InputAdornment position="start">
      <Fab
        color="danger"
        size="small"
        disabled={invoiceInputFields.length === 1}
        onClick={() => handleRemoveFieldsInvoice(inputField.id)}
      >
        <Tooltip title="Remove Contact">
          <RemoveIcon />
        </Tooltip>
      </Fab>
    </InputAdornment>
  </div>
</div>
))}
</>
)}
/>
*/}
        <Divider></Divider>

        <div className='flex mt-8 mb-16  mx-2 website'>
          <Controller
            name='customer_website'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-2'
                label='Website'
                id='customer_website'
                variant='outlined'
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: '#fff',
                    border: '1px solid #e2e1e5',
                    padding: '6px 9px',
                    height: '43px',
                  },
                }}
              />
            )}
          />
        </div>

        <div className='flex mt-8 mb-16  mx-2'>
          <FormControl fullWidth>
            <InputLabel>Term</InputLabel>
            <Controller
              id='term'
              name='term'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  variant='outlined'
                  className='mt-8 mb-16 mx-2 term'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: '#fff',
                      border: '1px solid #e2e1e5',
                      padding: '6px 9px',
                      height: '43px',
                    },
                  }}
                >
                  <MenuItem key='1' value={'1'}>
                    Net 1
                  </MenuItem>
                  <MenuItem key='2' value={'3'}>
                    Net 3
                  </MenuItem>
                  <MenuItem key='3' value={'7'}>
                    Net 7
                  </MenuItem>
                  <MenuItem key='4' value={'15'}>
                    Net 15
                  </MenuItem>
                  <MenuItem key='5' value={'30'}>
                    Net 30
                  </MenuItem>
                  <MenuItem key='6' value={'45'}>
                    Net 45
                  </MenuItem>
                  <MenuItem key='7' value={'60'}>
                    Net 60
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>

          <Controller
            name='credit_limits'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-2'
                label='Credit Limit'
                id='credit_limits'
                variant='outlined'
                // type={'number'}
                fullWidth
                InputProps={
                  {
                    // startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }
                }
                sx={{
                  '& .MuiOutlinedInput-root': {
                    background: '#fff',
                    border: '1px solid #e2e1e5',
                    padding: '6px 9px',
                    height: '43px',
                  },
                }}
              />
            )}
          />
        </div>

        <div className='flex mt-8 mb-16  mx-2'>
          <Controller
            name='customer_notes'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className='mt-8 mb-16 mx-2'
                label='Customer Notes'
                id='customer_notes'
                variant='outlined'
                type={'number'}
                fullWidth
                multiline
                rows={3}
              />
            )}
          />
        </div>

        {/* <Button style={{color:"white"}}
className="whitespace-nowrap mx-4"
variant="contained"
color="third"

onClick={id ? handleUpdateCustomer : handleSaveCustomer}
>
{id ? 'Update' : 'Save'}
</Button> */}

        {/* <div className="flex flex-row flex-wrap">
<div className="w-full md:w-1/2 p-16 min-h-420 h-420">
<ReactApexChart
options={[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]}
series={[30, 40, 45, 50, 49, 60, 70, 91]}
type='bar'
height={500}
/>
</div>
<div className="flex w-full md:w-1/2 flex-wrap p-8">
<div className="w-full sm:w-1/2 p-12">
<Typography
className="text-12 font-semibold whitespace-nowrap"
color="textSecondary"
>
hola 
</Typography>
<Typography className="text-32 font-semibold tracking-tighter">
hola 2
</Typography>
<div className="h-64 w-full overflow-hidden">
<ReactApexChart
options={[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]}
series={[30, 40, 45, 50, 49, 60, 70, 91]}
type='bar'
height={500}
/>
</div>
</div>
</div>
</div> */}
        <Button
          style={{ color: 'white' }}
          className='whitespace-nowrap mx-4'
          variant='contained'
          color='third'
          /**disabled={!isValid || disabled}**/
          onClick={id ? handleUpdateCustomer : handleSaveCustomer}
        >
          {id ? 'Update' : 'Save'}
        </Button>
      </div>
    </>
  );
}

export default memo(CreateCustomers);
