import { memo, useEffect, useState, useCallback } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import client from 'client';
import { connect } from 'react-redux';
import { hasPermission } from 'app/main/loadz/utils';
import ManagemenpaymentMethods from '../account/ManagemenpaymentMethods';

// Generamos el array de fechas de la semana y su formato
const week = [
  moment().day('Sunday').format('MM/DD/YYYY'),
  moment().day('Monday').format('MM/DD/YYYY'),
  moment().day('Tuesday').format('MM/DD/YYYY'),
  moment().day('Wednesday').format('MM/DD/YYYY'),
  moment().day('Thursday').format('MM/DD/YYYY'),
  moment().day('Friday').format('MM/DD/YYYY'),
  moment().day('Saturday').format('MM/DD/YYYY'),
];

const formatDateArray = (dateArray) =>
  dateArray.map((dateStr) => moment(dateStr, 'MM/DD/YYYY').format('ddd MM/DD'));

const formattedArray = formatDateArray(week);

const formatNumber = (num) => {
  let formatted = num.toLocaleString();
  // Asegura que tenga dos decimales en caso de tener decimales
  if (formatted.includes('.') && formatted.split('.')[1].length < 2) {
    formatted += '0';
  }
  // Elimina separadores de miles si existen
  return formatted.replace(/,/g, '');
};

function ChartCompleted({ refreshGraph, compacct, user }) {
  const [series, setSeries] = useState([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const [chartOptions] = useState({
    chart: {
      height: 350,
      type: 'area',
      offsetY: 0,
    },
    fill: {
      gradient: {},
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: formattedArray,
      labels: {
        datetimeFormatter: {
          day: 'ddd MM/dd',
        },
      },
    },
    yaxis: {
      decimalsInFloat: 0,
    },
    tooltip: {
      enabled: hasPermission('dashboard'),
      x: {
        format: 'dddd MM/dd',
      },
    },
    legend: {
      show: true,
      offsetY: 10,
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
      labels: {
        useSeriesColors: true,
      },
    },
    markers: {},
  });

  // Función para obtener los métodos de pago del usuario
  const fetchPaymentMethod = useCallback(async (userId) => {
    try {
      const response = await client.post('/payments/get-payment-method', {
        id: userId,
        payment_source: 1,
        all_method: true,
      });
      const paymentData = response.data;
      // Solo se abre el modal si aún no se mostró en esta sesión y no existen métodos de pago
      if (
        !sessionStorage.getItem('managemenPaymentModalShown') &&
        paymentData &&
        paymentData.length === 0
      ) {
        setOpenPaymentModal(true);
        sessionStorage.setItem('managemenPaymentModalShown', 'true');
      }
      return paymentData;
    } catch (error) {
      console.error('Error fetching payment methods:', error);
      return [];
    }
  }, []);

  // Función para obtener y procesar los datos para el gráfico
  const fetchChartData = useCallback(async () => {
    try {
      const { user_id, company } = user;
      const { compacctnum } = company;

      // Llamada a la ruta de gráfico y de métodos de pago (esta última en paralelo a otras peticiones)
      const chartPromise = client.get(`/jobs/get-for-dashboard-graph/${compacctnum}`);
      const paymentPromise = fetchPaymentMethod(user_id);

      // Llamadas paralelas para obtener datos adicionales
      const [fuelResponse, ticketResponse, subconticketResponse, chartResponse] = await Promise.all(
        [
          client.post(`/mileage/weekly-fuel`, { comp_id: user_id }),
          client.post(`/ticket/ticket-cost`, { comp_acct: compacctnum }),
          client.post(`/ticket/subconticket-cost`, { comp_acct: compacctnum }),
          chartPromise,
        ]
      );

      // Validación de la respuesta del gráfico
      const chartData = chartResponse.data;
      if (chartData?.msg) {
        return;
      }

      // Arrays para los datos
      const materialCr = [];
      const ticketCr = [];
      const waitTimeCr = [];

      if (chartData && chartData.length > 0) {
        chartData.forEach((element) => {
          materialCr.push(element.materialCr || 0);
          ticketCr.push(element.ticketCr || 0);
          waitTimeCr.push(element.waitTime || 0);
        });
      }

      // Procesamos los datos de fuel, ticket y subconticket
      const fuelCosts = fuelResponse.data.day_totals.map((elem) => formatNumber(elem.total));
      const ticketCosts = ticketResponse.data.day_totals.map((elem) => elem.total);
      const subconticketCosts = subconticketResponse.data.day_totals.map((elem) => elem.total);

      // Actualizamos el estado con la serie para el gráfico
      setSeries([
        {
          name: 'Hauling Sales',
          data: ticketCr,
        },
        {
          name: 'Material Sales',
          data: materialCr,
        },
        {
          name: 'Wait Time Charges',
          data: waitTimeCr,
        },
        {
          name: 'Fuel Costs',
          data: fuelCosts,
        },
        {
          name: 'Employee Commissions',
          data: ticketCosts,
        },
        {
          name: 'Owner Operator Commissions',
          data: subconticketCosts.length ? subconticketCosts : [0],
        },
      ]);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  }, [user, fetchPaymentMethod]);

  // Al montar el componente, registramos y ejecutamos la función de refresco
  useEffect(() => {
    if (refreshGraph) {
      refreshGraph.current = fetchChartData;
    }
    fetchChartData();
  }, [fetchChartData, refreshGraph]);

  return (
    <>
      {openPaymentModal && (
        <ManagemenpaymentMethods open={openPaymentModal} onClose={setOpenPaymentModal} />
      )}
      <Card
        className='w-full rounded-none'
        style={{ border: '3px solid #edca33', backgroundColor: '#FFFFFF' }}
      >
        <div className='p-20 pb-0'>
          <Typography className='h2 font-Regular'>
            {moment(week[0]).format('MM/DD/YYYY')} to {moment(week[6]).format('MM/DD/YYYY')}
          </Typography>
          <div className='flex flex-row flex-wrap items-center mt-12'>
            <Typography className='text-48 font-Regular leading-none tracking-tighter' />
          </div>
        </div>
        <div className='h-300 w-100-p'>
          <ReactApexChart options={chartOptions} series={series} type='area' height={350} />
        </div>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.loadz_auth?.AuthReducer?.user,
});

export default memo(connect(mapStateToProps, null)(ChartCompleted));
