import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import FuseMessage from '@fuse/core/FuseMessage';
import { constants, formatter } from '../../../../constants';
import { showMessage } from 'app/store/fuse/messageSlice';
import { Typography } from '@mui/material';
import CustomersHelpersHeader from './CustomersHelpersHeaders';
import CreateCustomers from './CreateCustomers';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      minHeight: 136,
      height: 136,
    },
  },
}));

const schema = yup.object().shape({
  customer_name: yup.string().required('You must enter a customer name'), //TODO improved message
});

function CustomersHelper(props) {
  const { setSelectedCustomer, setCustomerId } = props;
  const prop_data = props.prop_data;
  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams();
  //const { id } = routeParams;
  const { id } = props;
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jsonGetById, setJsonGetById] = useState([]);
  const token = localStorage.token;

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      customer_name: '',
      customer_email: '',
      // l_name:'',
      l_address: '',
      l_city: '',
      l_state: '',
      l_zip: '',
      contact: [],
      customer_website: '',
      term: '3', //TODO default value being preloaded. Perhaps we must have a Configuration File or Variable for this.
      credit_limits: formatter.format(Number(100000)),
      customer_notes: '',
    },
    resolver: yupResolver(schema),
  });

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: (
          <>
            <Typography>{msg}</Typography>
          </>
        ), //text or html
        autoHideDuration: 6000, //ms

        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const handleSearchCustomerById = async () => {
    if (id) {
      setLoading(true);
      try {
        const _getJobById = await fetch(`${constants.URLLOCAL}/customers/by-id/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: `${token}`,
          },
        });
        const _jsonGetById = await _getJobById.json();

        if (_jsonGetById.msg || _jsonGetById.length == 0) {
          history.push('/customers');
          setLoading(false);
        }

        setJsonGetById(_jsonGetById);

        setLoading(false);
      } catch (error) {
        prop_data ? props.closeIt() : history.push('/customers');
        setLoading(false);
      }
    }
  };

  const { setValue, setError } = methods;

  useEffect(() => {
    if (id) {
      handleSearchCustomerById();
      return;
    }
    setError('customer_name');
  }, []);

  if (id && loading) {
    return <FuseLoading></FuseLoading>;
  }

  return (
    <FormProvider {...methods}>
      <FuseMessage />
      <Root
        header={
          <CustomersHelpersHeader
            setSelectedCustomer={setSelectedCustomer}
            setCustomerId={setCustomerId}
            prop_data={prop_data}
            closeIt={props.closeIt}
            id={id}
            jsonGetById={id ? jsonGetById : []}
          />
        }
        content={
          <>
            <Container maxWidth='lg'>
              <CreateCustomers
                setSelectedCustomer={setSelectedCustomer}
                setCustomerId={setCustomerId}
                prop_data={prop_data}
                closeIt={props.closeIt}
                refreshCustomers={props.refreshCustomers}
                id={id}
                jsonGetById={id ? jsonGetById : []}
              />
            </Container>
          </>
        }
        innerScroll
      />
    </FormProvider>
  );
}

export default memo(CustomersHelper);
