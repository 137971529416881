import actions from './actions';
import { getErrorAction, getSuccessAction, update } from '../utility';

const initState = {
  user: null,
  loading: false,
  onboardingSteps: null,
  feVersion: null,
  version: null,
};

export default function (state = initState, action) {
  switch (action.type) {
    case actions.LOG_IN:
      return {
        ...state,
        loading: true,
      };

    case getSuccessAction(actions.LOG_IN):
      return {
        ...state,
        user: {
          ...action.payload.user,
        },
        loading: true,
        onboardingSteps: null,
        credentials: null,
      };

    case getErrorAction(actions.LOG_IN):
      return {
        ...state,
        loading: false,
      };

    case actions.GENERAL_REGISTRATION:
      return {
        ...state,
        loading: true,
      };

    case getSuccessAction(actions.GENERAL_REGISTRATION):
      return {
        ...state,
        loading: false,
        credentials: null,
        user: action.payload.user,
        onboardingSteps: null,
        error: null,
      };

    case getErrorAction(actions.GENERAL_REGISTRATION):
      return {
        ...state,
        loading: false,
      };

    case actions.REQUEST_CODE:
      return {
        ...state,
        loading: true,
        credentials: action.payload,
      };

    case getSuccessAction(actions.REQUEST_CODE):
      return {
        ...state,
        loading: false,
        error: null,
      };

    case getErrorAction(actions.REQUEST_CODE):
      return {
        ...state,
        loading: false,
      };

    case actions.RESET_STATE:
      console.log('RESETTING STATE')
      return {
        user: null,
        loading: false,
        onboardingSteps: null,
        feVersion: null,
        version: null,
      };

    case getSuccessAction(actions.DO_ONBOARDING):
      const { data, step } = action.payload;
      const steps = state.onboardingSteps?.steps
        ? {
            ...state.onboardingSteps.steps,
            [`step${step}`]: { ...data },
          }
        : {
            ...state.onboardingSteps,
            [`step${step}`]: { ...data },
          };
      //changed the 5 to a 6 to account for the dwolla step
      const onboardingStep = action.step >= 6 ? 6 : step + 1;
      const user = { ...state.user, reg_step: onboardingStep.toString() };
      return {
        ...state,
        onboardingSteps: {
          steps,
        },
        user: {
          ...user,
        },
      };

    case update(actions.ONBOARDING_STEP):
      const currentstep = Number(state.user.reg_step) > 1 ? Number(state.user.reg_step) - 1 : 1;
      return {
        ...state,
        user: {
          ...state.user,
          reg_step: currentstep.toString(),
        },
      };
    case actions.SKIP_STEP:
      return {
        ...state,
        user: {
          ...state.user,
          reg_step: (state.user.onboardingStep + 1).toString(),
        },
      };

    case 'ASSIGNED_USER_PERMISSION':
      return {
        ...state,
        user: {
          ...state.user,
          permission: action.payload.permission,
        },
      };

    case actions.SET_COMPANY_LOGO:
      return {
        ...state,
      };

    case getSuccessAction(actions.SET_COMPANY_LOGO):
      return {
        ...state,
        user: {
          ...state.user,
          company: {
            ...state.user.company,
            logo: action.payload.key,
          },
        },
      };

    case actions.SET_USER_LOGO:
      return {
        ...state,
      };

    case getSuccessAction(actions.SET_USER_LOGO):
      return {
        ...state,
        user: {
          ...state.user,
          profile_img: action.payload.key,
        },
      };

    case actions.UPDATE_VERSIONS:
      return {
        ...state,
        version: action.payload.version,
        feVersion: action.payload.feVersion,
      };
    default:
      return state;
  }
}
