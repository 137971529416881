import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { constants } from '../../../../../constants';
import client from 'client';
import { showMessage } from 'app/store/fuse/messageSlice';
import ButtonLog from 'app/main/ButtonLog';

const styles = {
  width: '100%',
};

function ReceiptMultiEmail(props) {
  const dispatch = useDispatch();

    const _showMessage = (message,color) => {
        dispatch(
        showMessage({
            message: message, //text or html
            autoHideDuration: 6000, //ms
            anchorOrigin: {
            vertical: 'top', //top bottom
            horizontal: 'right', //left center right
            },
            variant: color, //success error info warning null
        })
        );
    };

  // console.log('props email => ', props);
  let emailData = props && props?.customer_email && props?.customer_email.length ? props.customer_email : null;
  const [emails, setEmails] = useState(emailData ? [...emailData] : []);

  const sendEmail = async () => {
    //console.log('emails => ', emails);
    //console.log('paymentData => ', props.paymentData)
    //console.log('toTable => ', props.toTable);
    //console.log('paymentReceiptData => ', props.paymentReceiptData);

    ButtonLog({type:'payment',info:`emails:[${emails.join(', ')}] , event_id:${props.event_id}`,button_name: 'resend_payment_receipt'})

    if(emails.length === 0){_showMessage('Please enter an email address','error'); return}

    const {event_id} = props

    //api call
    /*
    get event id from payment
    send to route
    route will look up event then depending if send-email-payment-receipt or send-email-payment-receipt-over it will call the correct function and send email
    */

    /**
     * pending to create route above already saves data to payments_event.receipt_copy
     */

    const result = await client.post('/payments/re-send-payment-receipt', {eventID:event_id})
    console.log('result => ', result.data);

    if(result.data.status){ _showMessage(result.data.message,'success')}
    else{ _showMessage(result.data.message,'error')}



    props.emailhandleOpen();
    return
  }


  return (
    <div style={styles}>
      <ReactMultiEmail
        placeholder='Input your Email Address'
        emails={emails}
        onChange={(_emails) => {
          setEmails(_emails);
        }}
        getLabel={(email, index, removeEmail = index) => {
          return (
            <Chip
              className='mr-4'
              data-tag-handle
              label={email}
              onDelete={() => removeEmail(index)}
            />
          );
        }}
      />
      <div className='text-center'>
        <Button variant='contained' onClick={(e) =>{e.stopPropagation();sendEmail()}} className='mx-auto mt-8 cursor-pointer'>
          Send Email
        </Button>
      </div>
      <br />
    </div>
  );
}

export default ReceiptMultiEmail;
