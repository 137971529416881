import { memo, useEffect, useRef, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { AvatarGroup, Button, Checkbox, LinearProgress, Typography,Box } from '@mui/material';
import store from 'app/store'
import Avatar from '@mui/material/Avatar';
import { constants } from '../../../../constants';
import _ from '@lodash';
import useOpenImagePreview from 'app/hooks/useOpenImagePreview';
import ViewCustomerBolImages from './ViewCustomerBolImages';


export function BolTable({
    data,
    setSelectedBOL,
    selectedBOL,
    tabValue
}) {

    const [opencollapse, setOpenCollapse] = useState(false);
    const user = store.getState()?.loadz_auth?.AuthReducer?.user
    const [openPreview, setOpenPreview] = useState(false);

    return(
        <>

            <ViewCustomerBolImages
                open={openPreview}
                closePreview={setOpenPreview}
                images={Array.isArray(data.tickets[0]?.bol_file) ? data.tickets[0]?.bol_file : [data.tickets[0]?.bol_file]}
            />

            <TableRow
                 sx={{ cursor: 'pointer' }}
                 onClick={(e) => {
                     e.stopPropagation();
                     setOpenCollapse(!opencollapse);
                 }}
            >
                <TableCell
                    align='center'
                    sx={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenCollapse(!opencollapse);
                    }}
                >
                    {opencollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </TableCell>
                <TableCell align='center'>{user.company.company_id} - {data.tickets[0].job_no}</TableCell>

                <TableCell 
                    align='center' sx={{ justifyItems: 'center',position:'relative'}}
                    onClick={(e)=>{
                        e.stopPropagation(); 
                        setOpenPreview(true)
                    }}
                >   

                  
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <Avatar
                            alt="Bill of Lading image"
                            src={`${constants.URLLOCAL}/aws/signed?image=${Array.isArray(data.tickets[0]?.bol_file) ? data.tickets[0]?.bol_file[0]?.key : data.tickets[0]?.bol_file?.key}`}
                            sx={{
                                border: '2px solid black !important',
                                boxSizing: 'border-box', 
                                cursor: 'pointer',
                            }}
                        />
                        <Box 
                        sx={{
                            position: 'absolute', 
                            top: -5, 
                            right: -5, 
                            backgroundColor: '#edca33', 
                            color: 'black', 
                            borderRadius: 10, 
                            zIndex: 1, 
                            padding: '3px'
                        }}
                        >
                        <Typography fontWeight={'bold'} variant='caption'>
                            {Array.isArray(data.tickets[0]?.bol_file) ?  `+${data.tickets[0]?.bol_file?.length}` : `+${[data.tickets[0]?.bol_file]?.length}` }
                        </Typography>
                        </Box>
                    </Box>
                    

                </TableCell>
                <TableCell align='center'>{data.tickets[0].origin}</TableCell>
                <TableCell align='center'>{data.tickets[0].destination}</TableCell>
            </TableRow>

            <TableRow>
                <TableCell sx={{padding:'8px'}} colSpan={5} align='center'>
                    <Collapse in={opencollapse} timeout="auto" unmountOnExit>
                        <TableContainer component={Paper} sx={{ margin: 1, maxWidth: 'calc(100% - 16px)' }}>
                            <Table stickyHeader>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#edca33' }}>
                                   {tabValue != 4 ?  <TableCell
                                        className='font-bold'
                                        align='center'
                                    >
                                        <Checkbox
                                            checked={data.tickets.every((ticket) => selectedBOL.some((selected) => selected.tk_id === ticket.tk_id))}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                console.log(e.target.checked);
                                                const allTickets = data.tickets.map((ticket) => ({
                                                tk_id: ticket.tk_id,
                                                invoice_no:'',
                                                }));
                                            
                                                if (e.target.checked) {
                                                    console.log('selectedBOL => ',selectedBOL)
                                                    console.log('allTickets',allTickets)
                                                setSelectedBOL([
                                                    ...selectedBOL,
                                                    ...allTickets.filter((ticket) => !selectedBOL.some((selected) => selected.tk_id === ticket.tk_id))
                                                ]);
                                                } else {
                                                setSelectedBOL([]); // Deselect all
                                                }
                                            }}
                                        />
                                    </TableCell>:null}
                                    <TableCell
                                        sx={{ backgroundColor: '#edca33' }}
                                        className='font-bold'
                                        align='center'
                                    >
                                        Ticket No.
                                    </TableCell>
                                    <TableCell
                                        sx={{ backgroundColor: '#edca33' }}
                                        className='font-bold'
                                        align='center'
                                    >
                                        Created At
                                    </TableCell>
                                    <TableCell
                                        sx={{ backgroundColor: '#edca33' }}
                                        className='font-bold'
                                        align='center'
                                    >
                                        Updated At
                                    </TableCell>
                                    <TableCell
                                        sx={{ backgroundColor: '#edca33' }}
                                        className='font-bold'
                                        align='center'
                                    >
                                        Enter invoice #
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {data.tickets?.map((elem) => (
                                    <TableRow key={elem.tk_id}>
                                        {tabValue != 4 ? <TableCell align='center'>
                                            <Checkbox
                                                checked={selectedBOL.some((selected) => selected.tk_id === elem.tk_id)}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    console.log(e.target.checked);
                                                    if (e.target.checked) {
                                                        setSelectedBOL((prevSelectedBOL) => {
                                                            const isElemInSelectedBOL = prevSelectedBOL.some((selected) => selected.tk_id === elem.tk_id);
                                                          
                                                            if (!isElemInSelectedBOL) {
                                                              return [...prevSelectedBOL, {tk_id: elem.tk_id, invoice_no:''}];
                                                            }
                                                          
                                                            return prevSelectedBOL;
                                                          });
                                                    } else {
                                                    setSelectedBOL(selectedBOL.filter((selected) => selected.tk_id !== elem.tk_id  ))
                                                    }
                                                }}
                                            />
                                        </TableCell>:null}
                                        <TableCell align='center'>{elem.tk_no}</TableCell>
                                        <TableCell align='center'>{moment(elem.date_created).format('MMMM DD, YYYY hh:mm A')}</TableCell>
                                        <TableCell align='center'>{moment(elem.date_updated).format('MMMM DD, YYYY hh:mm A')}</TableCell>
                                        <TableCell align='center'>
                                            <input
                                                style={{
                                                // position: 'fixed', // Mantiene el botón flotante
                                                bottom: '20px', // Separación de la parte inferior
                                                right: '20px', // Separación del lado derecho
                                                color: '#000', // Color negro del texto
                                                padding: '10px 20px', // Tamaño del botón
                                                borderRadius: '25px', // Esquinas redondeadas
                                                fontFamily: 'Arial, sans-serif', // Fuente
                                                fontSize: '14px', // Tamaño de texto
                                                fontWeight: 'bold', // Texto en negrita
                                                textAlign: 'center', // Texto centrado
                                                cursor: tabValue != 4 ? 'pointer' : '', // Manito al pasar por encima
                                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)', // Sombra del botón
                                                transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Animación suave
                                                }}
                                                disabled={tabValue == 4 ? true : !selectedBOL.some((selected) => selected.tk_id === elem.tk_id)}
                                                type='text'
                                                value={
                                                    tabValue == 4 ?
                                                    elem.reconcile_invoice_no : 
                                                    selectedBOL.find((selected) => selected.tk_id === elem.tk_id)?.invoice_no || ''
                                                   
                                                }
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    const newText = e.target.value;
                                                    console.log(newText)
                                                    setSelectedBOL((prevSelectedBol) => {
                                                      const index = prevSelectedBol.findIndex((selected) => selected.tk_id === elem.tk_id);
                                                      if (index !== -1) {
                                                        const updatedSelectedBol = [...prevSelectedBol];
                                                        updatedSelectedBol[index].invoice_no = newText;
                                                        return updatedSelectedBol;
                                                      } else {
                                                        return prevSelectedBol;
                                                      }
                                                    });
                                                  }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
               
    )
    
}