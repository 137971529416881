import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import history from '@history';
import { Router, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrLangDir } from 'app/store/i18nSlice';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';
import { useEffect, useState, useRef, useMemo } from 'react';
import client from 'client';
import { Alert, Button, Snackbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import store from './store';
import AuthActions from '../app/stores/loadz/redux/Auth/actions';
import { useJsApiLoader } from '@react-google-maps/api';

import { v4 as uuidv4 } from 'uuid';
import Idle from 'react-idle';

// import axios from 'axios';
/**
 * Axios HTTP Request defaults
 */
// axios.defaults.baseURL = "";
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// window.addEventListener("beforeunload", function (e) {
//   var confirmationMessage = "\o/";

//   (e || window.event).returnValue = confirmationMessage; //Gecko + IE
//   return confirmationMessage;                            //Webkit, Safari, Chrome
// });

const StyledSnackbar = withStyles({
  close: {
    display: 'none',
  },
})(Snackbar);

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    prepend: true,
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    prepend: true,
  },
};

const App = () => {
  const langDirection = useSelector(selectCurrLangDir);
  const [openAlert, setOpenAlert] = useState(false);
  const [idleAlert, setIdleAlert] = useState(false);

  const browserId = localStorage.getItem('browser_id');

  const dispatch = useDispatch();
  const state = store.getState();

  const getVersion = async () => {
    const version = state.loadz_auth?.AuthReducer?.version;
    const feVersion = state.loadz_auth?.AuthReducer?.feVersion;
    const result = await client.get('/app/version');

    if (feVersion !== result?.data?.feVersion || version !== result?.data?.version) {
      dispatch(
        AuthActions.updateVersion({
          version: result.data.version,
          feVersion: result.data.feVersion,
        })
      );
      setOpenAlert(true);
      return true;
    }

    return false;
  };

  const handleAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const hasNewVersion = await getVersion();
      if (hasNewVersion) {
        clearInterval(interval);
      }
    }, 300000);
  }, []);

  if (!browserId) {
    const id = uuidv4();
    localStorage.setItem('browser_id', `${process.env.NODE_ENV}_${id}`);
  }

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00:00');

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const reallyResetState =() => {
    return new Promise((resolve, reject) => {
      dispatch(AuthActions.logout())
      resolve()
    })
  }

  const startTimer = async (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : '0' + hours) +
          ':' +
          (minutes > 9 ? minutes : '0' + minutes) +
          ':' +
          (seconds > 9 ? seconds : '0' + seconds)
      );
    }
    if (total == 0) {
      
      reallyResetState().then(() => {
        localStorage.removeItem('token');
        location.replace('/');
      })
     

      
    
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('00:02:00');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <Auth>
        <Router history={history}>
          <FuseAuthorization>
            <FuseTheme>
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <FuseLayout />
              </SnackbarProvider>
            </FuseTheme>
          </FuseAuthorization>
        </Router>
        <StyledSnackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert
            onClose={handleAlert}
            severity="info"
            sx={{ width: '100%' }}
          >
            <Typography
              variant="h5"
              className="cursor-pointer"
            >
              New App Version is available.{' '}
              <Button
                variant="outlined"
                onClick={() => location.reload()}
              >
                Update
              </Button>
            </Typography>
          </Alert>
        </StyledSnackbar>

        {useMemo(() => {
          //console.log('store.getState()?.loadz_auth?.AuthReducer?.user',store.getState())
          return (
            <Idle
              timeout={7200000}
              onChange={({ idle }) => {
                if (store.getState()?.loadz_auth?.AuthReducer?.user && localStorage.getItem('token')) {
                  if (idle) {
                    setIdleAlert(true);
                    clearTimer(getDeadTime());
                  } else {
                    setIdleAlert(false);
                    clearInterval(Ref.current);
                  }
                }
              }}
            />
          )
        }, [store.getState()?.loadz_auth?.AuthReducer?.user,localStorage.getItem('token')])}

        <StyledSnackbar
          open={idleAlert}
          autoHideDuration={60000}
          onClose={(e) => {
            e.stopPropagation();
            setIdleAlert(false);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Alert
            onClose={(e) => {
              e.stopPropagation();
              setIdleAlert(false);
            }}
            severity="info"
            sx={{ width: '100%' }}
          >
            <Typography
              variant="h5"
              className="flex flex-row items-center justify-center align-center"
            >
              Inactive! you will be logged out in {timer}
            </Typography>
          </Alert>
        </StyledSnackbar>
      </Auth>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
//<FuseCountdown endDate={moment().add(5,'seconds').format()} />
