// MainComponent.jsx
import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

/**
 * ModalReportOptions
 *
 * Este componente muestra un modal para que el usuario seleccione el formato del reporte:
 * - Detailed View: Muestra solamente el desglose de los tickets (sin la sección de subtotales de camiones).
 * - Summary View: Muestra solamente el subtotal de cada camión (sin el desglose de tickets).
 * - Both: Se genera el reporte actual sin modificaciones.
 *
 * Props:
 * - open: (boolean) Indica si el modal está abierto.
 * - onClose: (function) Función para cerrar el modal.
 * - onSelectOption: (function) Callback que recibe la opción seleccionada.
 */
const ModalReportOptions = ({ open, onClose, onSelectOption }) => {
  const [selectedOption, setSelectedOption] = useState('Both');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    onSelectOption(selectedOption);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Report Format</DialogTitle>
      <DialogContent>
        <RadioGroup value={selectedOption} onChange={handleChange}>
          <FormControlLabel value='Detail_View' control={<Radio />} label='Detailed View' />
          <FormControlLabel value='Summary_View' control={<Radio />} label='Summary View' />
          <FormControlLabel value='Both' control={<Radio />} label='Both' />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant='contained'>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalReportOptions;
