import AppBar from '@mui/material/AppBar';
import { styled, alpha } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useHistory } from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import Customers from './Customers';
import CustomersData from './CustomersData';
import { Box, Button, Dialog, DialogActions, DialogTitle, Input } from '@mui/material';
import { useState, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { formatter } from '../../../../constants';
import ModalToEditCustomers from './ModalToEditCustomers';
import store from 'app/store';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ModalGenerateInvoices from 'app/main/Invoices/Invoices/Modals/ModalGenerateInvoices';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import client from 'client';
import { InvoiceReportModal } from 'app/main/Customers/NewCustomersComponents/InvoiceReportModal';
import moment from 'moment-timezone';
import ButtonLog from 'app/main/ButtonLog';
import PhoneInput from 'react-phone-input-2';
const drawerWidth = 250;
const headerHeight = 200;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100%',
  position: 'relative',
  flex: '1 1 auto',
  height: 'auto',
  backgroundColor: theme.palette.background.default,

  '& .ChatApp-topBg': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    // backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
    backgroundColor: theme.palette.primary.dark,
    backgroundSize: 'cover',
    pointerEvents: 'none',
  },

  '& .ChatApp-contentCardWrapper': {
    position: 'relative',
    padding: 24,
    maxWidth: 1600,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    minWidth: '0',
    maxHeight: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 12,
    },
  },

  '& .ChatApp-contentCard': {
    display: 'flex',
    position: 'relative',
    flex: '1 1 100%',
    flexDirection: 'row',
    // backgroundImage: 'url("/assets/images/patterns/rain-grey.png")',
    backgroundColor: theme.palette.background.paper,
    minHeight: 0,
    overflow: 'hidden',
  },

  '& .ChatApp-contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    zIndex: 10,
    background: `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0.8)} 0,${alpha(
      theme.palette.background.paper,
      0.6
    )} 20%,${alpha(theme.palette.background.paper, 0.8)})`,
  },

  '& .ChatApp-content': {
    display: 'flex',
    flex: '1 1 100%',
    minHeight: 0,
  },
}));

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    maxWidth: '100%',
    overflow: 'hidden',
    // height: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomersApp({
  refreshCustomers,
  rows,
  // setInfoCustomer,
  // infoCustomer,
  rechargedAfterChanges,
  setMessage,
  userInfo,
  setRows,
  prop_data,
  finish,
  scrollNextPage,
  setScrollNextPage,
}) {
  const [infoCustomer, setInfoCustomer] = useState([]);
  const [bolData, setBolData] = useState([]);

  const [avatar, setAvatar] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [create, setCreate] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showInvoiceInApply, setShowInvoiceInApply] = useState(new Map());
  const [infoPayments, setInfoPayments] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [info, setInfo] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const [showResults, setShowResults] = useState(false);
  const [globalSearch, setGlobalSearch] = useState(false);
  const [globalSearchText, setGlobalSearchText] = useState('');
  const [archive, setArchive] = useState(false);
  const openModalCustomer = useRef(null);
  const openApplyPaymentsPerCustomer = useRef(null);
  const openApplyPaymentPerInvoice = useRef(null);

  const getInvoicesPayable = useRef(null);

  const openModalViewInvoice = useRef(null);

  const [tabValue, setTabValue] = useState(0);
  const [jobNo, setJobNo] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [paymentsearch, setPaymentSearch] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const handleChangeTab = (event, newValue) => {
    setJobNo('');
    setInvoiceNo('');
    setPaymentSearch('');
    setDate1('');
    setDate2('');
    setInvoiceDate1('');
    setInvoiceDate2('');
    if (newValue === 0 && customerId) {
      // getInvoicesPayable.current(customerId)
    }

    if (newValue === 1 && customerId) {
      getInvoicesToPayable(customerId);
    }

    if (newValue === 2 && customerId) {
      getPaymentPerCustomer(customerId);
    }
    setSorting({
      invoice: { active: false, order: 'asc' },
      job: { active: false, order: 'asc' },
      dateUpdated: { active: false, order: 'asc' },
      dueDate: { active: false, order: 'asc' },
      totalHauling: { active: false, order: 'asc' },
      totalWait: { active: false, order: 'asc' },
      material: { active: false, order: 'asc' },
      grandTotal: { active: false, order: 'asc' },
      balancePending: { active: false, order: 'asc' },
      agent: { active: false, order: 'asc' },
      current: { column: '', order: '' },
      flag: false,
    });
    setTabValue(newValue);
  };

  const handleClick = () => {
    setCreate(false);
    openModalCustomer.current(true);
  };

  const dwollaClick = () => {
    const { id } = store.getState();
    history.replace(`/dwolla/${id}`);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleApplyPayment = () => {
    openApplyPaymentsPerCustomer.current(customerId);
  };

  const handleApplyPaymentInvoice = (invoiceId) => {
    openApplyPaymentPerInvoice.current(customerId, invoiceId);
  };

  const getInvoicesToPayable = async () => {};

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: <Typography>{msg}</Typography>,
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: color,
      })
    );
  };

  const sendEmailCustomerDwolla = async () => {
    try {
      const IdCompany = prop_data ? prop_data?.id : userInfo?.user_id;
      if (selectedCustomer?.customer_email) {
        await client.get(
          `/customers/send-email-customer/${customerId}/${selectedCustomer?.customer_email}/${IdCompany}`
        );
        _showMessage('Customer Invitation sent successfully', 'success'); //TODO improved Message
      } else {
        _showMessage(
          'Unable to send the invitation. The customer has not provided an email address',
          'error'
        ); // TODO improved Message
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPaymentPerCustomer = async () => {};

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const clearselectedCustomer = () => {
    setSelectedCustomer(null);
    setCustomerId(null);
    setInfoPayments([]);
    setInfoCustomer([]);
    setInvoiceNo('');
    setPaymentSearch('');
    setDate2('');
    setDate1('');
    setInvoiceDate1('');
    setInvoiceDate2('');
    setSorting({
      invoice: { active: false, order: 'asc' },
      job: { active: false, order: 'asc' },
      dateUpdated: { active: false, order: 'asc' },
      dueDate: { active: false, order: 'asc' },
      totalHauling: { active: false, order: 'asc' },
      totalWait: { active: false, order: 'asc' },
      material: { active: false, order: 'asc' },
      grandTotal: { active: false, order: 'asc' },
      balancePending: { active: false, order: 'asc' },
      agent: { active: false, order: 'asc' },
      current: { column: '', order: '' },
      flag: false,
    });
  };

  const handleArchive = async (event) => {
    try {
      await client.get(`/customers/archive-customer/${customerId}/${event}`);
      _showMessage(`Customer ${event} Successfully`, 'success');
      clearselectedCustomer();
      setShowResults(true);
      handleClose();
      handleCloseDelete();
      setArchive(null);
      setSorting({
        invoice: { active: false, order: 'asc' },
        job: { active: false, order: 'asc' },
        dateUpdated: { active: false, order: 'asc' },
        dueDate: { active: false, order: 'asc' },
        totalHauling: { active: false, order: 'asc' },
        totalWait: { active: false, order: 'asc' },
        material: { active: false, order: 'asc' },
        grandTotal: { active: false, order: 'asc' },
        balancePending: { active: false, order: 'asc' },
        agent: { active: false, order: 'asc' },
        current: { column: '', order: '' },
        flag: false,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const getCustomerFromInvoice = async () => {
    const result = await client.get(
      `/invoices/find-customer/${
        prop_data ? prop_data?.compacctnum : userInfo?.company?.compacctnum
      }/${globalSearchText}/${archive ? 0 : 1}`
    );
    //console.log("result.data",result.data)
    if (result.data?.data.length) {
      let balance = false;
      const balanceStr = result.data?.data[0]?.invoices[0].balance;
      const numericPart = balanceStr.split('$')[1];
      balance =
        balanceStr[0] === '-' || numericPart === '0' || numericPart === '0.00' ? false : true;

      const found_cust = rows.find((cust) => {
        if (cust?.customer?.customers_id == result.data?.data[0]?.customer_id) {
          return true;
        }
      });
      if (found_cust) {
        setJobNo(globalSearchText);
        setInvoiceNo('');
        setPaymentSearch('');
        setDate2('');
        setDate1('');
        setCustomerId(result.data?.data[0]?.customer_id);
        setSelectedCustomer(found_cust.customer);
        setTabValue(balance ? 0 : 1);
        setSorting({
          invoice: { active: false, order: 'asc' },
          job: { active: false, order: 'asc' },
          dateUpdated: { active: false, order: 'asc' },
          dueDate: { active: false, order: 'asc' },
          totalHauling: { active: false, order: 'asc' },
          totalWait: { active: false, order: 'asc' },
          material: { active: false, order: 'asc' },
          grandTotal: { active: false, order: 'asc' },
          balancePending: { active: false, order: 'asc' },
          agent: { active: false, order: 'asc' },
          current: { column: '', order: '' },
          flag: false,
        });
      } else {
        const new_rows = rows;
        new_rows.push(result.data?.customer[0]);
        setRows(new_rows);
        setJobNo(globalSearchText);
        setInvoiceNo('');
        setPaymentSearch('');
        setDate2('');
        setDate1('');
        setCustomerId(result.data?.data[0]?.customer_id);
        setSelectedCustomer(result.data?.customer[0].customer);
        setTabValue(balance ? 0 : 1);
        setSorting({
          invoice: { active: false, order: 'asc' },
          job: { active: false, order: 'asc' },
          dateUpdated: { active: false, order: 'asc' },
          dueDate: { active: false, order: 'asc' },
          totalHauling: { active: false, order: 'asc' },
          totalWait: { active: false, order: 'asc' },
          material: { active: false, order: 'asc' },
          grandTotal: { active: false, order: 'asc' },
          balancePending: { active: false, order: 'asc' },
          agent: { active: false, order: 'asc' },
          current: { column: '', order: '' },
          flag: false,
        });
      }
    } else {
      return _showMessage(`No Customer With That Invoice`, 'warning');
    }
  };
  const [globalTicketSearch, setGlobalTicketSearch] = useState(false);
  const [globalTicketSearchText, setGlobalTicketSearchText] = useState('');
  const [hasGlobalTicketSearched, setHasGlobalTicketSearched] = useState(false);
  const [hasGlobalTicketSearchedLoading, setHasGlobalTicketSearchedLoading] = useState(false);
  const [globalResults, setGlobalResults] = useState([]);

  const getCustomerFromInvoiceTicket = async () => {
    //this will set the rows setRows
    //on clear or close call getData to get customers again
    //if they open gloabl invoice search then getData if they have searched global tickets
    setGrandTotal(null);
    setGrandTotalBalance(null);
    setGrandTotalHauling(null);
    setGrandTotalWait(null);
    setGrandTotalMaterial(null);
    setGrandTotalAgent(null);
    setHasGlobalTicketSearched(true);
    const result = await client.get(
      `/invoices/find-customer-ticket/${
        prop_data ? prop_data?.compacctnum : userInfo?.company?.compacctnum
      }/${globalTicketSearchText}/${archive ? 0 : 1}`
    );
    //console.log("result.data",result.data)

    setHasGlobalTicketSearchedLoading(false);

    if (result.data?.customers.length) {
      setGlobalResults(result.data?.data);
      setRows(result.data?.customers);

      /*  let balance = false;
      result.data?.data[0]?.invoices[0].balance.split('$')[1] == 0
        ? (balance = false)
        : (balance = true);

      const found_cust = rows.find((cust) => {
        if (cust?.customer?.customers_id == result.data?.data[0]?.customer_id) {
          return true;
        }
      });

      if (found_cust) {
        setJobNo(globalSearchText);
        setInvoiceNo('');
        setPaymentSearch('');
        setDate2('');
        setDate1('');
        setCustomerId(result.data?.data[0]?.customer_id);
        setSelectedCustomer(found_cust.customer);
        setTabValue(balance ? 0 : 1);
      } else {
        const new_rows = rows;
        new_rows.push(result.data?.customer[0]);
        setRows(new_rows);
        setJobNo(globalSearchText);
        setInvoiceNo('');
        setPaymentSearch('');
        setDate2('');
        setDate1('');
        setCustomerId(result.data?.data[0]?.customer_id);
        setSelectedCustomer(result.data?.customer[0].customer);
        setTabValue(balance ? 0 : 1);
      } */
    } else {
      setGlobalResults([]);
      setRows(result.data?.customers);
      return _showMessage(`No Customer With That Scale No./Ticket No.`, 'warning');
    }
  };

  const [invoiceDate1, setInvoiceDate1] = useState('');
  const [invoiceDate2, setInvoiceDate2] = useState('');
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [listLoading, setListLoading] = useState(false);
  const [disableList, setDisableList] = useState(false);

  const getPerCustomerDate = async (page = 1) => {
    if (!invoiceDate1 || !invoiceDate2) {
      return _showMessage(`Please select a date range`, 'warning');
    }
    const user = prop_data ? prop_data : userInfo;
    const status = tabValue == 1 ? 0 : 1;
    if (customerId && user) {
      setDisableList(true);
      const result = await client.get(
        `/invoices/customer-date/${
          prop_data ? prop_data.compacctnum : user?.company?.compacctnum
        }/${customerId}/${status}/${invoiceDate1}/${invoiceDate2}`,
        {
          params: {
            page,
          },
        }
      );

      let new_data = [...infoCustomer];
      if (page == 1) {
        const only_invoices = [];
        result.data.map(async (job) => {
          job.invoices.map(async (invoice) => {
            only_invoices.push(invoice);
          });
        });
        if (result.data.length) {
          setGrandTotal(result.data[0].totals.grandTotal);
          setGrandTotalBalance(result.data[0].totals.grandTotalBalance);
          setGrandTotalHauling(result.data[0].totals.grandTotalHauling);
          setGrandTotalWait(result.data[0].totals.grandTotalWait);
          setGrandTotalMaterial(result.data[0].totals.grandTotalMaterial);
          setGrandTotalAgent(result.data[0].totals.grandTotalAgentCommission);
        }
        if (sorting.flag) {
          SortColumn(sorting.current.order, sorting.current.column, only_invoices);
        } else {
          setInfoCustomer(only_invoices);
        }
      } else {
        const only_invoices = [];
        result.data.map(async (job) => {
          job.invoices.map(async (invoice) => {
            only_invoices.push(invoice);
          });
        });
        new_data.push(...only_invoices);
        if (sorting.flag) {
          SortColumn(sorting.current.order, sorting.current.column, new_data);
        } else {
          setInfoCustomer(new_data);
        }
      }

      if (result.data?.length > 0) {
        setPage(page);
      } else {
        setHasNextPage(false);
      }
      setInfoPayments([]);

      setDisableList(false);
    }
  };

  const handleDateSearch = async () => {
    if (!globalTicketSearch && !globalSearch) {
      if (tabValue == 0 || tabValue == 1) {
        setPage(1);
        setHasNextPage(true);
        getPerCustomerDate();
      }
    }
  };

  /* useEffect(() => {
    if(customerId){
      console.log('customer changes',customerId)
      setTabValue(0)
    }
  }, [customerId]) */

  const [sorting, setSorting] = useState({
    invoice: { active: false, order: 'asc' },
    job: { active: false, order: 'asc' },
    dateUpdated: { active: false, order: 'asc' },
    dueDate: { active: false, order: 'asc' },
    totalHauling: { active: false, order: 'asc' },
    totalWait: { active: false, order: 'asc' },
    material: { active: false, order: 'asc' },
    grandTotal: { active: false, order: 'asc' },
    balancePending: { active: false, order: 'asc' },
    agent: { active: false, order: 'asc' },
    current: { column: '', order: '' },
    flag: false,
  });

  const SortColumn = (order, column, invoices) => {
    let rows = invoices ? invoices : [...infoCustomer];

    let newSorting = { ...sorting };

    let currentColumn = newSorting.current.column;
    if (currentColumn) {
      newSorting[currentColumn].active = false;
      newSorting[currentColumn].order = 'asc';
    }

    newSorting[column].active = true;
    newSorting[column].order = order;

    newSorting.current.column = column;
    newSorting.current.order = order;
    newSorting.flag = true;

    setSorting(newSorting);

    let sortedInvoices = [];

    switch (column) {
      case 'invoice':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort((a, b) => Number(b.invoice) - Number(a.invoice));
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort((a, b) => Number(a.invoice) - Number(b.invoice));
        }
        setInfoCustomer(sortedInvoices);

        break;

      case 'job':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort((a, b) => Number(b.job) - Number(a.job));
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort((a, b) => Number(a.job) - Number(b.job));
        }
        setInfoCustomer(sortedInvoices);

        break;

      case 'dateUpdated':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              new Date(...b.dateUpdated.split('/').reverse()) -
              new Date(...a.dateUpdated.split('/').reverse())
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              new Date(...a.dateUpdated.split('/').reverse()) -
              new Date(...b.dateUpdated.split('/').reverse())
          );
        }
        setInfoCustomer(sortedInvoices);

        break;

      case 'dueDate':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              new Date(...b.dueDate.split('/').reverse()) -
              new Date(...a.dueDate.split('/').reverse())
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              new Date(...a.dueDate.split('/').reverse()) -
              new Date(...b.dueDate.split('/').reverse())
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      case 'totalHauling':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(b.hauling.replace(/\$|,/g, '')) - Number(a.hauling.replace(/\$|,/g, ''))
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(a.hauling.replace(/\$|,/g, '')) - Number(b.hauling.replace(/\$|,/g, ''))
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      case 'totalWait':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) => Number(b.wait.replace(/\$|,/g, '')) - Number(a.wait.replace(/\$|,/g, ''))
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) => Number(a.wait.replace(/\$|,/g, '')) - Number(b.wait.replace(/\$|,/g, ''))
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      case 'grandTotal':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) => Number(b.total.replace(/\$|,/g, '')) - Number(a.total.replace(/\$|,/g, ''))
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) => Number(a.total.replace(/\$|,/g, '')) - Number(b.total.replace(/\$|,/g, ''))
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      case 'material':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(b.material.replace(/\$|,/g, '')) +
              Number(b.taxesMaterial.replace(/\$|,/g, '')) -
              (Number(a.material.replace(/\$|,/g, '')) +
                Number(a.taxesMaterial.replace(/\$|,/g, '')))
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(a.material.replace(/\$|,/g, '')) +
              Number(a.taxesMaterial.replace(/\$|,/g, '')) -
              (Number(b.material.replace(/\$|,/g, '')) +
                Number(b.taxesMaterial.replace(/\$|,/g, '')))
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      case 'balancePending':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(b.balance.replace(/\$|,/g, '')) - Number(a.balance.replace(/\$|,/g, ''))
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(a.balance.replace(/\$|,/g, '')) - Number(b.balance.replace(/\$|,/g, ''))
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      case 'agent':
        if (order == 'desc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(b.agent_commission.replace(/\$|,/g, '')) -
              Number(a.agent_commission.replace(/\$|,/g, ''))
          );
        }
        if (order == 'asc') {
          sortedInvoices = [...rows].sort(
            (a, b) =>
              Number(a.agent_commission.replace(/\$|,/g, '')) -
              Number(b.agent_commission.replace(/\$|,/g, ''))
          );
        }
        setInfoCustomer(sortedInvoices);
        break;

      default:
        break;
    }

    return;
  };

  const [grandTotalHauling, setGrandTotalHauling] = useState(null);
  const [grandTotalWait, setGrandTotalWait] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [grandTotalBalance, setGrandTotalBalance] = useState(null);
  const [grandTotalMaterial, setGrandTotalMaterial] = useState(null);
  const [grandTotalAgent, setGrandTotalAgent] = useState(null);

  const [openInvoiceReport, setOpenInvoiceReport] = useState(false);
  const [reportFullDateStart, setReportFullDateStart] = useState('');
  const [reportFullDateEnd, setReportFullDateEnd] = useState('');
  const [reportFullLoading, setReportFullLoading] = useState(false);
  const [monthFullSelector, setMonthFullSelector] = useState('0');
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);

  const [fullReportData, setFullReportData] = useState(null);

  const [groupByCustomer, setGroupByCustomer] = useState(false);
  const [groupByJob, setGroupByJob] = useState(false);

  const [compLogo, setCompLogo] = useState('');

  const generateInvoiceReport = async () => {
    try {
      if (selectedCustomers.length == 0 || selectedJobs.length == 0 || selectedStatus.length == 0) {
        selectedCustomers.length == 0
          ? _showMessage('Please select the customers', 'error')
          : selectedJobs.length == 0
          ? _showMessage('Please select the jobs', 'error')
          : _showMessage('Please select the status of the invoices', 'error');
        return;
      }

      setReportFullLoading(true);

      const result = await client.post('/invoices/generate-report', {
        startdate: reportFullDateStart,
        enddate: reportFullDateEnd,
        jobs: selectedJobs,
        customers: selectedCustomers,
        status: selectedStatus,
        compacctnum: userInfo?.company?.compacctnum,
      });

      ButtonLog({
        type: 'client',
        info: ', GroupByCustomer: ' + groupByCustomer + ', GroupByJob: ' + groupByJob,
        button_name: 'client_invoice_report',
      });

      setFullReportData(result.data.sorted_invoices);
      setCompLogo(result.data.complogo);

      setReportFullLoading(false);
    } catch (error) {
      console.log(error);
      setFullReportData({});
      setCompLogo('');
      setReportFullLoading(false);
    }
  };

  const handleFullMonthChange = (value) => {
    setMonthFullSelector(value);

    setReportFullDateStart(moment(value).startOf('month').format('YYYY-MM-DD'));
    setReportFullDateEnd(moment(value).endOf('month').format('YYYY-MM-DD'));
  };

  return (
    <>
      <InvoiceReportModal
        open={openInvoiceReport}
        onClose={() => {
          setFullReportData(null);
          setSelectedJobs([]);
          setSelectedStatus([]);
          setSelectedCustomers([]);
          setReportFullDateEnd('');
          setReportFullDateStart('');
          setMonthFullSelector('0');
          setOpenInvoiceReport(false);
          setGroupByCustomer(false);
          setGroupByJob(false);
        }}
        monthSelector={monthFullSelector}
        reportDateStart={reportFullDateStart}
        reportDateEnd={reportFullDateEnd}
        setReportDateStart={setReportFullDateStart}
        setReportDateEnd={setReportFullDateEnd}
        handleMonthChange={handleFullMonthChange}
        reportFullLoading={reportFullLoading}
        generateInvoiceReport={generateInvoiceReport}
        selectedCustomers={selectedCustomers}
        setSelectedCustomers={setSelectedCustomers}
        user={userInfo}
        selectedJobs={selectedJobs}
        setSelectedJobs={setSelectedJobs}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        fullReportData={fullReportData}
        compLogo={compLogo}
        setGroupByCustomer={setGroupByCustomer}
        setGroupByJob={setGroupByJob}
        groupByCustomer={groupByCustomer}
        groupByJob={groupByJob}
      />

      <ModalGenerateInvoices
        prop_data={prop_data}
        tickets={tickets}
        openModalInvoices={openModalViewInvoice}
        info={info}
      />

      <ModalToEditCustomers
        prop_data={prop_data}
        refreshCustomers={refreshCustomers}
        id={customerId ? customerId : null}
        setOpen={openModalCustomer}
        rechargedAfterChanges={rechargedAfterChanges}
        create={create}
        setCustomerId={setCustomerId}
        setSelectedCustomer={setSelectedCustomer}
      />

      <GlobalStyles
        styles={(theme) => ({
          '#fuse-main': {
            height: '100vh',
          },
        })}
      />

      <Root>
        <div className='ChatApp-topBg' />

        <div className={clsx('ChatApp-contentCardWrapper', 'container', 'overflow-hidden')}>
          <div className={clsx('ChatApp-contentCard', 'shadow rounded-20 customer-full-table')}>
            {mobileOpen ? (
              <Hidden mdUp>
                <StyledSwipeableDrawer
                  className='h-full absolute z-20'
                  variant='temporary'
                  anchor='left'
                  open={true}
                  onOpen={(ev) => {}}
                  onClose={() => {
                    handleDrawerToggle();
                  }}
                  disableSwipeToOpen
                  classes={{
                    paper: 'absolute ltr:left-0 rtl:right-0',
                  }}
                  style={{ position: 'absolute' }}
                  ModalProps={{
                    keepMounted: true,
                    disablePortal: true,
                    BackdropProps: {
                      classes: {
                        root: 'absolute',
                      },
                    },
                  }}
                >
                  <Customers
                    setOpenInvoiceReport={setOpenInvoiceReport}
                    finish={finish}
                    sorting={sorting}
                    setSorting={setSorting}
                    listLoading={listLoading}
                    setListLoading={setListLoading}
                    disableList={disableList}
                    page={page}
                    setPage={setPage}
                    hasNextPage={scrollNextPage}
                    setHasNextPage={setScrollNextPage}
                    invoiceDate1={invoiceDate1}
                    invoiceDate2={invoiceDate2}
                    setInvoiceDate1={setInvoiceDate1}
                    setInvoiceDate2={setInvoiceDate2}
                    handleDateSearch={handleDateSearch}
                    getPerCustomerDate={getPerCustomerDate}
                    globalResults={globalResults}
                    setGlobalResults={setGlobalResults}
                    hasGlobalTicketSearched={hasGlobalTicketSearched}
                    setHasGlobalTicketSearched={setHasGlobalTicketSearched}
                    hasGlobalTicketSearchedLoading={hasGlobalTicketSearchedLoading}
                    setHasGlobalTicketSearchedLoading={setHasGlobalTicketSearchedLoading}
                    globalTicketSearch={globalTicketSearch}
                    setGlobalTicketSearch={setGlobalTicketSearch}
                    globalTicketSearchText={globalTicketSearchText}
                    setGlobalTicketSearchText={setGlobalTicketSearchText}
                    getCustomerFromInvoiceTicket={getCustomerFromInvoiceTicket}
                    setInfoCustomer={setInfoCustomer}
                    getCustomerFromInvoice={getCustomerFromInvoice}
                    globalSearchText={globalSearchText}
                    setGlobalSearchText={setGlobalSearchText}
                    globalSearch={globalSearch}
                    setGlobalSearch={setGlobalSearch}
                    prop_data={prop_data}
                    refreshCustomers={refreshCustomers}
                    rows={rows}
                    setAvatar={setAvatar}
                    setCustomerId={setCustomerId}
                    avatar={avatar}
                    rechargedAfterChanges={rechargedAfterChanges}
                    handleDrawerToggle={handleDrawerToggle}
                    openApplyPaymentsPerCustomer={openApplyPaymentsPerCustomer}
                    openApplyPaymentPerInvoice={openApplyPaymentPerInvoice}
                    customerId={customerId}
                    setMessage={setMessage}
                    showInvoiceInApply={showInvoiceInApply}
                    getInvoicesPayable={getInvoicesPayable}
                    setTabValue={setTabValue}
                    setInfoPayments={setInfoPayments}
                    setShowInvoiceInApply={setShowInvoiceInApply}
                    tickets={tickets}
                    setTickets={setTickets}
                    setInfo={setInfo}
                    info={info}
                    openModalViewInvoice={openModalViewInvoice}
                    archive={archive}
                    setArchive={setArchive}
                    setRows={setRows}
                    setJobNo={setJobNo}
                    setInvoiceNo={setInvoiceNo}
                    setPaymentSearch={setPaymentSearch}
                    setDate2={setDate2}
                    setDate1={setDate1}
                    setSelectedCustomer={setSelectedCustomer}
                  />
                </StyledSwipeableDrawer>
              </Hidden>
            ) : null}

            <Hidden mdDown>
              <StyledSwipeableDrawer
                className='h-full z-20'
                variant='permanent'
                open
                onOpen={(ev) => {}}
                onClose={(ev) => {}}
              >
                <Customers
                  setOpenInvoiceReport={setOpenInvoiceReport}
                  finish={finish}
                  sorting={sorting}
                  setSorting={setSorting}
                  listLoading={listLoading}
                  setListLoading={setListLoading}
                  disableList={disableList}
                  page={page}
                  setPage={setPage}
                  hasNextPage={scrollNextPage}
                  setHasNextPage={setScrollNextPage}
                  invoiceDate1={invoiceDate1}
                  invoiceDate2={invoiceDate2}
                  setInvoiceDate1={setInvoiceDate1}
                  setInvoiceDate2={setInvoiceDate2}
                  handleDateSearch={handleDateSearch}
                  getPerCustomerDate={getPerCustomerDate}
                  globalResults={globalResults}
                  setGlobalResults={setGlobalResults}
                  hasGlobalTicketSearched={hasGlobalTicketSearched}
                  setHasGlobalTicketSearched={setHasGlobalTicketSearched}
                  hasGlobalTicketSearchedLoading={hasGlobalTicketSearchedLoading}
                  setHasGlobalTicketSearchedLoading={setHasGlobalTicketSearchedLoading}
                  globalTicketSearch={globalTicketSearch}
                  setGlobalTicketSearch={setGlobalTicketSearch}
                  globalTicketSearchText={globalTicketSearchText}
                  setGlobalTicketSearchText={setGlobalTicketSearchText}
                  getCustomerFromInvoiceTicket={getCustomerFromInvoiceTicket}
                  setInfoCustomer={setInfoCustomer}
                  getCustomerFromInvoice={getCustomerFromInvoice}
                  globalSearchText={globalSearchText}
                  setGlobalSearchText={setGlobalSearchText}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  prop_data={prop_data}
                  refreshCustomers={refreshCustomers}
                  rows={rows}
                  setAvatar={setAvatar}
                  setCustomerId={setCustomerId}
                  avatar={avatar}
                  rechargedAfterChanges={rechargedAfterChanges}
                  openApplyPaymentsPerCustomer={openApplyPaymentsPerCustomer}
                  openApplyPaymentPerInvoice={openApplyPaymentPerInvoice}
                  customerId={customerId}
                  setMessage={setMessage}
                  showInvoiceInApply={showInvoiceInApply}
                  getInvoicesPayable={getInvoicesPayable}
                  setTabValue={setTabValue}
                  setInfoPayments={setInfoPayments}
                  setShowInvoiceInApply={setShowInvoiceInApply}
                  tickets={tickets}
                  setTickets={setTickets}
                  setInfo={setInfo}
                  info={info}
                  openModalViewInvoice={openModalViewInvoice}
                  archive={archive}
                  setArchive={setArchive}
                  setRows={setRows}
                  setJobNo={setJobNo}
                  setInvoiceNo={setInvoiceNo}
                  setPaymentSearch={setPaymentSearch}
                  setDate2={setDate2}
                  setDate1={setDate1}
                  setSelectedCustomer={setSelectedCustomer}
                />
              </StyledSwipeableDrawer>
            </Hidden>

            <main className={clsx('ChatApp-contentWrapper', 'z-10 ', 'overflow-x-auto')}>
              <AppBar className='w-full ' elevation={0} position='static'>
                <Toolbar className='px-16 justify-end'>
                  <IconButton
                    color='inherit'
                    aria-label='Open drawer'
                    onClick={() => handleDrawerToggle()}
                    className='flex md:hidden'
                    size='large'
                  >
                    <Icon>chat</Icon>
                  </IconButton>
                  <div
                    className='w-full flex flex-col items-start cursor-pointer justify-items-start'
                    // onClick={() => dispatch(openContactSidebar())}
                    // onKeyDown={() => dispatch(openContactSidebar())}
                    role='button'
                    tabIndex={0}
                  >
                    <div className='relative mx-8'>
                      <div className='absolute right-0 bottom-0 -m-4 z-10'></div>
                    </div>
                    <h1 className='custom-heading'>Customer Details</h1>
                  </div>
                </Toolbar>
              </AppBar>
              <div>
                <div className='ChatApp-content flex flex-col w-full'>
                  {selectedCustomer?.customer_name || selectedCustomer?.contact?.[0]?.name ? (
                    <div className='new-uninvoiced-details flex items-end justify-between m-16 main-column-outter'>
                      <div className='main-column main-column1'>
                        <div class='user-detail'>
                          {selectedCustomer?.customer_name ? (
                            <h4 className='invoice-main-title font-bold'>
                              {selectedCustomer?.customer_name}
                            </h4>
                          ) : null}
                          {selectedCustomer?.contact?.[0]?.name ? (
                            <div className='user-name cst_user'>
                              <strong className='mr-3'>Contact Name:</strong>
                              {selectedCustomer?.contact?.[0]?.name}
                            </div>
                          ) : null}
                          {selectedCustomer?.l_city &&
                          selectedCustomer?.l_state &&
                          selectedCustomer?.l_zip &&
                          selectedCustomer?.l_address ? (
                            <div className='user-add cst_user'>
                              <strong className='mr-3'>Address:</strong>
                              {selectedCustomer?.l_address},{selectedCustomer?.l_city},
                              {selectedCustomer?.l_state},{selectedCustomer?.l_zip}
                            </div>
                          ) : null}
                          {selectedCustomer?.customer_email ? (
                            <div className='user-email cst_user pr-8'>
                              <strong className='mr-3'>Email:</strong>
                              <a style={{ color: 'blue' }} href='mailto:gabgarsolution@gmail.com'>
                                {selectedCustomer?.customer_email}
                              </a>
                            </div>
                          ) : (
                            <strong className='mr-3'>No Email</strong>
                          )}

                          {selectedCustomer?.customer_phone ? (
                            <div className='user-number cst_user'>
                              <strong className='mr-3'>Phone Number:</strong>

                              <PhoneInput
                                disabled
                                id='user_phone'
                                className='mb-24'
                                value={
                                  selectedCustomer
                                    ? `${selectedCustomer?.customer_country_code || '+1'}${
                                        selectedCustomer?.customer_phone
                                      }`
                                    : `${selectedCustomer?.customer_country_code || '+1'} ${
                                        selectedCustomer?.customer_phone
                                      }`
                                }
                                inputStyle={{ height: '30px', width: 'auto' }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className='main-column main-column2'>
                        <div className='user-price'>
                          {selectedCustomer?.credit_limits ? (
                            <div className='user-add'>
                              <strong className='mr-3'>Credit Limit:</strong>
                              {formatter.format(Number(selectedCustomer?.credit_limits)) == '$NaN'
                                ? selectedCustomer?.credit_limits
                                : formatter.format(Number(selectedCustomer?.credit_limits))}
                            </div>
                          ) : null}
                          {selectedCustomer?.balanceTotal ? (
                            <div className='user-add'>
                              <strong className='mr-3'>Balance:</strong>
                              {formatter.format(Number(selectedCustomer?.balanceTotal))}
                            </div>
                          ) : null}
                          {selectedCustomer?.term ? (
                            <div className='user-add'>
                              <strong className='mr-3'>Net:</strong>
                              {selectedCustomer?.term}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className='main-column main-column3'>
                        <div className='user-edit-detail'>
                          <div className='main-column3-inner'>
                            <Tooltip
                              title='Invite the customer to set up their Client portal and enable the Payment Service'
                              placement='top'
                            >
                              <div className='lodaz-icon' onClick={() => sendEmailCustomerDwolla()}>
                                <span className='icon-title'>Invite to Set Up Customer Portal</span>
                              </div>
                            </Tooltip>
                            <Tooltip title='Apply Payments' placement='top'>
                              <div
                                className='lodaz-icon'
                                onClick={() => {
                                  if (selectedCustomer?.balanceTotal > 0) {
                                    handleApplyPayment();
                                  }
                                }}
                              >
                                {/* <img src="/assets/images/xx.svg" alt="" className="m-auto" /> //TODO Line 506 reference to gabgarsolution Message */}
                                <span className='icon-title'>Apply Payments</span>
                              </div>
                            </Tooltip>
                            <Tooltip title='Edit Customer' placement='top'>
                              <div className='lodaz-icon' onClick={() => handleClick()}>
                                <span className='icon-title'>Edit Customer Details</span>
                              </div>
                            </Tooltip>
                            <Tooltip title='Archive Customer' placement='top'>
                              <div className='lodaz-icon'>
                                <Dialog
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{ style: { backgroundColor: 'black' } }}
                                >
                                  <DialogTitle style={{ color: 'white' }}>
                                    {selectedCustomer?.balanceTotal > 0
                                      ? 'Are you sure you want to Archive'
                                      : 'Would you like to delete or archive'}{' '}
                                    {selectedCustomer?.customer_name ||
                                      selectedCustomer?.contact?.[0]?.name}{' '}
                                    ?{' '}
                                  </DialogTitle>
                                  <DialogActions style={{ justifyContent: 'center' }}>
                                    {selectedCustomer?.balanceTotal <= 0 ? (
                                      <Button
                                        onClick={handleOpenDelete}
                                        style={{
                                          backgroundColor:
                                            selectedCustomer?.balanceTotal > 0 ? '#FFC300' : 'red',
                                          color: 'white',
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    ) : null}

                                    <Button
                                      onClick={() => {
                                        handleArchive('archive');
                                      }}
                                      style={{ backgroundColor: '#FFC300', color: 'white' }}
                                    >
                                      Archive
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                <Dialog
                                  open={openDelete}
                                  onClose={handleCloseDelete}
                                  PaperProps={{ style: { backgroundColor: 'black' } }}
                                >
                                  <DialogTitle style={{ color: 'white' }}>
                                    Are you sure you want to delete{' '}
                                    {selectedCustomer?.customer_name ||
                                      selectedCustomer?.contact?.[0]?.name}{' '}
                                    ?
                                  </DialogTitle>
                                  <DialogActions style={{ justifyContent: 'center' }}>
                                    <Button
                                      onClick={() => {
                                        handleArchive('delete');
                                      }}
                                      style={{ backgroundColor: '#FFC300', color: 'white' }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      onClick={handleCloseDelete}
                                      style={{ backgroundColor: 'red', color: 'white' }}
                                    >
                                      No
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                                <div
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                  className='archive-text-icon'
                                >
                                  <img src='/assets/images/printer.svg' alt='' className='m-auto' />
                                  <span className='icon-title'>Archive Customer</span>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <Box sx={{ width: '100%' }}>
                    {customerId && (
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <div className='flex flex-col items-center md:flex-row md:justify-between'>
                          <Tabs
                            value={tabValue}
                            onChange={handleChangeTab}
                            aria-label='basic tabs example'
                            className='flex w-full'
                            variant='scrollable'
                          >
                            <Tab label='Open Invoices' {...a11yProps(0)} />
                            <Tab label='Paid Invoices' {...a11yProps(1)} />
                            <Tab label='View Payments' {...a11yProps(2)} />
                            <Tab
                              hidden={!selectedCustomer?.bol_pending || selectedCustomer?.bol_pending <= 0}
                              label={
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span>({selectedCustomer?.bol_pending})</span>
                                  <span>Pending</span>
                                  <span>Bill of Ladings</span>
                                </div>
                              }
                              {...a11yProps(3)}
                            />
                            <Tab
                              hidden={
                                selectedCustomer?.bol_complete > 0
                                  ? false
                                  : selectedCustomer?.bol_pending <= 0
                                  ? true
                                  : !selectedCustomer?.bol_pending ? true : false
                              }
                              label={
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span>Completed</span>
                                  <span>Bill of Ladings</span>
                                </div>
                              }
                              {...a11yProps(4)}
                            />
                          </Tabs>
                          {tabValue === 0 || tabValue === 1 ? (
                            <div className='flex flex-col align-end justify-end items-center w-full  my-8 mx-4 pr-24'>
                              <div className='flex justify-end items-center w-full'>
                                <Input
                                  value={jobNo}
                                  style={{ height: '100%', color: 'black' }}
                                  onChange={(e) => setJobNo(e.target.value.replace(/\s/g, ''))}
                                  placeholder='Search Invoice'
                                  className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/5'
                                  disableUnderline
                                  type='number'
                                  fullWidth
                                  inputProps={{
                                    'aria-label': 'Search Invoice',
                                  }}
                                  disabled={invoiceNo.length ? true : false}
                                  autoFocus
                                />
                                <Icon color='action'>search</Icon>
                              </div>

                              <div className='flex align-end justify-end items-center w-full'>
                                {/* <Icon color="action">date_range</Icon> */}
                                <Input
                                  name='invoiceDate1'
                                  value={invoiceDate1}
                                  style={{ height: '100%', color: 'black' }}
                                  className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/5'
                                  disableUnderline
                                  fullWidth
                                  type='date'
                                  onChange={(e) => {
                                    setInvoiceDate1(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <Input
                                  name='invoiceDate2'
                                  value={invoiceDate2}
                                  style={{ height: '100%', color: 'black' }}
                                  className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/5'
                                  disableUnderline
                                  fullWidth
                                  type='date'
                                  onChange={(e) => {
                                    setInvoiceDate2(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <Button
                                  className='flex p-0'
                                  sx={{ minWidth: '24px', maxwidth: '24px' }}
                                  onClick={() => {
                                    handleDateSearch();
                                  }}
                                >
                                  <Icon color='action'>search</Icon>
                                </Button>
                              </div>
                            </div>
                          ) : tabValue === 2 ? (
                            <div className='flex flex-col justify-center items-center w-full md:max-w-512 ls:max-w-1024 my-8 mx-4'>
                              <div className='flex w-full items-center justify-end pb-4'>
                                <Input
                                  disabled={paymentsearch.length > 0 ? true : false}
                                  value={invoiceNo}
                                  style={{ height: '100%', color: 'black' }}
                                  className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-236'
                                  disableUnderline
                                  fullWidth
                                  type='number'
                                  placeholder='Search Payments'
                                  onChange={(e) => {
                                    if (RegExp('[0-9]').test(e.target.value.slice(-1)) == false) {
                                      e.target.value = e.target.value.slice(0, -1);
                                    }
                                    setInvoiceNo(e.target.value.replace(/\s/g, ''));
                                  }}
                                />
                                <Icon color='action'>search</Icon>
                              </div>
                              <div className='flex w-full items-center'>
                                <Input
                                  name='date1'
                                  value={date1}
                                  style={{ height: '100%', color: 'black' }}
                                  className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/2'
                                  disableUnderline
                                  fullWidth
                                  type='date'
                                  onChange={(e) => {
                                    setDate1(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <Input
                                  name='date2'
                                  value={date2}
                                  style={{ height: '100%', color: 'black' }}
                                  className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/2'
                                  disableUnderline
                                  fullWidth
                                  type='date'
                                  onChange={(e) => {
                                    setDate2(e.target.value);
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                                <Icon color='action'>date_range</Icon>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Box>
                    )}

                    <Hidden mdUp>
                      <CustomersData
                        openModalCustomer={openModalCustomer}
                        create={create}
                        setCreate={setCreate}
                        rows={rows}

                        setSelectedCustomer={setSelectedCustomer}
                        selectedCustomer={selectedCustomer}
                        refreshCustomers={refreshCustomers}
                        bolData={bolData}
                        setBolData={setBolData}
                        setGrandTotalHauling={setGrandTotalHauling}
                        setGrandTotalWait={setGrandTotalWait}
                        setGrandTotal={setGrandTotal}
                        setGrandTotalBalance={setGrandTotalBalance}
                        setGrandTotalMaterial={setGrandTotalMaterial}
                        setGrandTotalAgent={setGrandTotalAgent}
                        grandTotalMaterial={grandTotalMaterial}
                        grandTotalAgent={grandTotalAgent}
                        grandTotalHauling={grandTotalHauling}
                        grandTotalWait={grandTotalWait}
                        grandTotal={grandTotal}
                        grandTotalBalance={grandTotalBalance}
                        SortColumn={SortColumn}
                        sorting={sorting}
                        setSorting={setSorting}
                        globalTicketSearch={globalTicketSearch}
                        globalSearch={globalSearch}
                        listLoading={listLoading}
                        setListLoading={setListLoading}
                        setDisableList={setDisableList}
                        page={page}
                        setPage={setPage}
                        hasNextPage={hasNextPage}
                        setHasNextPage={setHasNextPage}
                        invoiceDate1={invoiceDate1}
                        invoiceDate2={invoiceDate2}
                        setInvoiceDate1={setInvoiceDate1}
                        setInvoiceDate2={setInvoiceDate2}
                        handleDateSearch={handleDateSearch}
                        getPerCustomerDate={getPerCustomerDate}
                        setTabValue={setTabValue}
                        hasGlobalTicketSearched={hasGlobalTicketSearched}
                        setHasGlobalTicketSearched={setHasGlobalTicketSearched}
                        globalResults={globalResults}
                        prop_data={prop_data}
                        handleDrawerToggle={handleDrawerToggle}
                        infoCustomer={infoCustomer}
                        rechargedAfterChanges={rechargedAfterChanges}
                        handleApplyPaymentInvoice={handleApplyPaymentInvoice}
                        setShowInvoiceInApply={setShowInvoiceInApply}
                        infoPayments={infoPayments}
                        tickets={tickets}
                        setTickets={setTickets}
                        setInfo={setInfo}
                        info={info}
                        openModalViewInvoice={openModalViewInvoice}
                        setInfoCustomer={setInfoCustomer}
                        customerId={customerId}
                        tabValue={tabValue}
                        setInfoPayments={setInfoPayments}
                        searchByJobNo={jobNo}
                        searchByInvoiceNo={invoiceNo}
                        paymentsearch={paymentsearch}
                        date1={date1}
                        date2={date2}
                      />
                    </Hidden>
                  </Box>
                </div>
                <Hidden mdDown>
                  <Box sx={{ width: '100%' }}>
                    <CustomersData
                      openModalCustomer={openModalCustomer}
                      create={create}
                      setCreate={setCreate}
                      rows={rows}
                      setSelectedCustomer={setSelectedCustomer}
                      selectedCustomer={selectedCustomer}
                      refreshCustomers={refreshCustomers}
                      bolData={bolData}
                      setBolData={setBolData}
                      setGrandTotalHauling={setGrandTotalHauling}
                      setGrandTotalWait={setGrandTotalWait}
                      setGrandTotal={setGrandTotal}
                      setGrandTotalBalance={setGrandTotalBalance}
                      setGrandTotalMaterial={setGrandTotalMaterial}
                      setGrandTotalAgent={setGrandTotalAgent}
                      grandTotalMaterial={grandTotalMaterial}
                      grandTotalAgent={grandTotalAgent}
                      grandTotalHauling={grandTotalHauling}
                      grandTotalWait={grandTotalWait}
                      grandTotal={grandTotal}
                      grandTotalBalance={grandTotalBalance}
                      SortColumn={SortColumn}
                      sorting={sorting}
                      setSorting={setSorting}
                      globalTicketSearch={globalTicketSearch}
                      globalSearch={globalSearch}
                      listLoading={listLoading}
                      setListLoading={setListLoading}
                      setDisableList={setDisableList}
                      page={page}
                      setPage={setPage}
                      hasNextPage={hasNextPage}
                      setHasNextPage={setHasNextPage}
                      invoiceDate1={invoiceDate1}
                      invoiceDate2={invoiceDate2}
                      setInvoiceDate1={setInvoiceDate1}
                      setInvoiceDate2={setInvoiceDate2}
                      handleDateSearch={handleDateSearch}
                      getPerCustomerDate={getPerCustomerDate}
                      setTabValue={setTabValue}
                      globalResults={globalResults}
                      hasGlobalTicketSearched={hasGlobalTicketSearched}
                      setHasGlobalTicketSearched={setHasGlobalTicketSearched}
                      prop_data={prop_data}
                      handleDrawerToggle={handleDrawerToggle}
                      infoCustomer={infoCustomer}
                      rechargedAfterChanges={rechargedAfterChanges}
                      handleApplyPaymentInvoice={handleApplyPaymentInvoice}
                      setShowInvoiceInApply={setShowInvoiceInApply}
                      infoPayments={infoPayments}
                      tickets={tickets}
                      setTickets={setTickets}
                      setInfo={setInfo}
                      info={info}
                      openModalViewInvoice={openModalViewInvoice}
                      setInfoCustomer={setInfoCustomer}
                      customerId={customerId}
                      tabValue={tabValue}
                      setInfoPayments={setInfoPayments}
                      searchByJobNo={jobNo}
                      searchByInvoiceNo={invoiceNo}
                      paymentsearch={paymentsearch}
                      date1={date1}
                      date2={date2}
                    />
                  </Box>
                </Hidden>
              </div>
            </main>
          </div>
        </div>
      </Root>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loadz_auth?.AuthReducer?.user,
  };
};
export default connect(mapStateToProps)(CustomersApp);
