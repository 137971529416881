import React, {useState,useCallback} from 'react';
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import { DialogActions } from '@material-ui/core';
import { element } from 'prop-types';
import moment from 'moment';
import store from 'app/store';
import { formatter, constants } from '../../../../../constants';
import ReceiptEmailPopup from './ReceiptEmailPopup';

const PaymentDialog = ({ isOpen, closeModal, paymentData, jobNo, toTable, ClientName, prop_data,paymentReceiptData }) => {
  console.log('paymentData=>>>', paymentData);
  //console.log('toTable=>>>', toTable);
  //console.log('ClientName=>>>', ClientName);
  //console.log('prop_data=>>>', prop_data);
  //console.log('paymentReceiptData=>>>', paymentReceiptData);
  var retrievedObject = localStorage.getItem('loginTypeData');
  const loginTypeData = JSON.parse(retrievedObject);

  const [openEmail, setOpenEmail] = useState(false);
  const [customer_email, setCustomer_email] = useState(null);

  
  const getCustomerEmail = useCallback(() => {
    let emails = []
    if(toTable && toTable.length && toTable[0].customer && toTable[0].customer.customerEmail){
      emails.push(toTable[0].customer.customerEmail)
    }

    //remove block comment to enable emails from customer contacts
    /* if(toTable && toTable.length && toTable[0].customer && toTable[0].customer.customerContact && toTable[0].customer.customerContact.length)
    {
      for(const element of toTable[0].customer.customerContact)
      {
        if(element.email){
          emails.push(element.email)
        }
      }
    } */

    //remove block comment to enable emails from customer invoice contacts
    /* if(toTable && toTable.length && toTable[0].customer && toTable[0].customer.invoiceContacts && toTable[0].customer.invoiceContacts.length)
    {
      for(const element of toTable[0].customer.invoiceContacts)
      {
        if(element.email){
          emails.push(element.email)
        }
      }
    } */

    return emails;
  }
  ,[toTable]);

  return (
    <div>
      {
        toTable ? 

        <ReceiptEmailPopup
          open={openEmail}
          emailhandleOpen={() => setOpenEmail(false)}
          customer_email={getCustomerEmail()}
          //toTable={toTable}
          event_id={paymentData.event_id}
          //paymentReceiptData={paymentReceiptData}
        />
        :
        null
    
      }

      <Dialog
        maxWidth={'lg'}
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ padding: '65px 100px' }}>

          {/**options top right */}
          <div
            className='flex flex-row justify-end items-center pb-32'
          >
            <Box
              className='flex flex-row justify-evenly items-center p-16 mt-12 mr-12'
              sx={{backgroundColor:'#e9c917',height:'35px',width:'90px', borderRadius:'10px'}}
            >

              <div
                onClick={() => setOpenEmail(true)}
                style={{ cursor: 'pointer' }}
              >
                <img
                  width="25px"
                  height="25px"
                  src="/assets/images/icons/email-icon-white.svg"
                  style={{ marginRight: '15px', marginTop: '0px' }}
                />
              </div>

              <div
                className="text-white"
                onClick={closeModal}
                style={{ cursor: 'pointer' }}
              >
                <img
                  width="25px"
                  height="25px"
                  src="/assets/images/icons/icons8-close-white.svg"
                />
              </div>

            </Box>
            
          </div>

          <table
            className=" text-left table-fixed"
            width="700"
            border="0"
            align="center"
            cellPadding="0"
            cellspacing="0"
            style={{ border: '1px solid #000000', margin: '0px auto' }}
          >
            <thead>
              <tr align="center">
                <td
                  className=""
                  style={{ padding: '15px 0px', background: '#000' }}
                >
                  <img
                    src="assets/images/logos/yellow_loadz_logo.svg"
                    style={{ width: '130px' }}
                  />
                </td>
              </tr>
            </thead>
            <tbody style={{ padding: '30px' }}>
              <tr align="center">
                <td
                  style={{
                    padding: '20px 0px 20px',
                    color: '#000',
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '16px',
                  }}
                >
                  <h4 style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>
                    Total Payment Amount <br />
                  </h4>
                  <h4 style={{ fontSize: '44px', fontWeight: '600', margin: '0' }}>
                    {`${formatter.format(paymentData && paymentData?.amount_received ? paymentData.amount_received : null)}`}
                  </h4>

                  {ClientName && toTable && toTable.length && toTable[0].customer && toTable[0].customer.customerName ? (
                    <h3 style={{ fontSize: '14px', fontWeight: '700', margin: '0' }}>
                      {' '}
                      From &nbsp; '{loginTypeData && loginTypeData.Copm_name}' &nbsp; To &nbsp; '{ClientName} '&nbsp;{' '}
                    </h3>
                  ) : (
                    <h3 style={{ fontSize: '14px', fontWeight: '700', margin: '0' }}>
                      {' '}
                      From &nbsp; '
                      {toTable && toTable.length && toTable[0].customer && toTable[0].customer.customerName && toTable[0].customer.customerName}{' '}
                      '&nbsp; To &nbsp; '{prop_data ? prop_data?.compname : store.getState().loadz_auth.AuthReducer.user.company.compname}'&nbsp;{' '}
                    </h3>
                  )}
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                >
                  <table
                    width="500"
                    border="0"
                    style={{ borderSpacing: '0', marginLeft: '48px' }}
                  >
                    <tbody>
                      <tr>
                        <td
                          align="center"
                          style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                        >
                          <table
                            width="500"
                            border="0"
                            style={{ borderSpacing: '0', marginLeft: '0' }}
                          >
                            <thead>
                              <tr>
                                <th style={{ fontSize: '16px', fontWeight: '700', textAlign: 'left', padding: '0 0 4px' }}>Payment Details</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="">
                                    <div className="flex justify-between">
                                      <div
                                        className="w-3/5"
                                        style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                      >
                                        Invoice No.
                                      </div>
                                      <div
                                        className="w-2/5"
                                        style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                      >
                                        Payment Applied
                                      </div>
                                    </div>

                                    <div className="flex justify-between">
                                      <div
                                        className="w-3/5"
                                        style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                                      >
                                        {paymentData && paymentData?.invoice_no ? paymentData.invoice_no : null}
                                      </div>

                                      <div
                                        className="w-2/5"
                                        style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                                      >{`${formatter.format(paymentData && paymentData?.amount ? paymentData.amount : null)}`}</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    borderTop: '1px solid #D4D2D2',
                    borderBottom: '1px solid #D4D2D2',
                    padding: '10px 0',
                  }}
                >
                  <table
                    width="500"
                    border="0"
                    style={{ borderSpacing: '0', marginLeft: '48px' }}
                  >
                    <tbody>
                      <tr>
                        <td
                          align="center"
                          style={{ borderTop: '1px solid #D4D2D2', padding: '10px 0' }}
                        >
                          <table
                            width="500"
                            border="0"
                            style={{ borderSpacing: '0', marginLeft: '0' }}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <div className="flex flex-row">
                                    <div className="flex justify-between flex-col w-3/5">
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                      >
                                        Payment Status
                                      </div>
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                      >
                                        Payment Method
                                      </div>
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                      >
                                        Payment Time
                                      </div>
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '600', textAlign: 'left' }}
                                      >
                                        Payment Date
                                      </div>
                                    </div>
                                    <div className="flex justify-between flex-col w-2/5">
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                                      >
                                        Paid
                                      </div>

                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '400', textAlign: 'left' }}
                                      >
                                        {paymentData && paymentData?.payment_source ? paymentData?.payment_source : null}
                                      </div>
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '500', textAlign: 'left' }}
                                      >
                                        {moment(paymentData && paymentData?.payment_date ? paymentData?.payment_date : null).format('MM/DD/YYYY')}
                                      </div>
                                      <div
                                        className="w-full"
                                        style={{ fontSize: '16px', fontWeight: '500', textAlign: 'left' }}
                                      >
                                        {moment(paymentData && paymentData?.payment_date ? paymentData?.payment_date : null).format('h:mm:ss a')}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td align="center">
                  <table
                    border="0"
                    style={{
                      background: '#fff',
                      borderSpacing: '0',
                      width: '90%',
                      padding: '15px 65px',
                      margin: '13px 0 25px',
                    }}
                  >
                    <tbody></tbody>
                  </table>
                </td>
              </tr>
            </tbody>
            <tfoot style={{ padding: '0 0 16px' }}>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <img
                    src="assets/images/logos/loadzlogo.png"
                    style={{ width: '130px', margin: '0 auto' }}
                  />
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    padding: '18px 0px 10px',
                    fontSize: '14px',
                    color: '#000',
                    fontWeight: '600',
                  }}
                >
                  {' '}
                  We Make Paper Ticketing Obsolete -{' '}
                  <a
                    href="#"
                    style={{ textDecoration: 'none' }}
                  >
                    Learn More
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  align="center"
                  style={{
                    padding: '0px 0 20px',
                    fontSize: '10px',
                    color: '#000',
                    fontWeight: '700',
                  }}
                >
                  {' '}
                  Copyright © 2022 Loadz.com, Inc. All rights reserved.{' '}
                </td>
              </tr>
            </tfoot>
          </table>

        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PaymentDialog;
