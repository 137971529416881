import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import CustomersList from './CustomersList';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Icon from '@mui/material/Icon';
import AppBar from '@mui/material/AppBar';
import Tooltip from '@mui/material/Tooltip';
import { Alert, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModalToEditCustomers from './ModalToEditCustomers';
import { saveAs } from 'file-saver';
import ArchiveIcon from '@mui/icons-material/Archive';
import { constants } from '../../../../constants';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Paid from '@mui/icons-material/Paid';
import Snackbar from '@mui/material/Snackbar';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ModalToReconcileAllCustomers from './ModalToReconcileAllCustomers';
import ModelBalance from 'app/main/Invoices/Invoices/Modals/ModelBalance';
import PaymentsIcon from '@mui/icons-material/Payments';
import ModalToApplyPayments from './ModalToApplyPayments';
import client from 'client';
import { connect } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { grey } from '@mui/material/colors';
import ModalPaymentReport from './ModalPaymentReport';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';
import FuseLoading from '@fuse/core/FuseLoading';
import moment from 'moment/moment';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ModalReportOptions from './ModalReportOptions';

function Customers({
  refreshCustomers,
  rows,
  rechargedAfterChanges,
  setCustomerId,
  handleDrawerToggle,
  openApplyPaymentsPerCustomer,
  openApplyPaymentPerInvoice,
  customerId,
  setMessage,
  showInvoiceInApply,
  setTabValue,
  setShowInvoiceInApply,
  tickets,
  setTickets,
  setInfo,
  info,
  openModalViewInvoice,
  setArchive,
  archive,
  setRows,
  userInfo,
  prop_data,
  setSelectedCustomer,
  setInvoiceNo,
  setPaymentSearch,
  setDate2,
  setDate1,
  setJobNo,
  globalSearchText,
  setGlobalSearchText,
  globalSearch,
  setGlobalSearch,
  getCustomerFromInvoice,
  setInfoCustomer,

  globalTicketSearch,
  setGlobalTicketSearch,
  globalTicketSearchText,
  setGlobalTicketSearchText,
  getCustomerFromInvoiceTicket,
  hasGlobalTicketSearched,
  setHasGlobalTicketSearched,
  hasGlobalTicketSearchedLoading,
  setHasGlobalTicketSearchedLoading,
  globalResults,
  setGlobalResults,
  setInvoiceDate1,
  setInvoiceDate2,
  invoiceDate1,
  invoiceDate2,
  getPerCustomerDate,
  handleDateSearch,
  page,
  setPage,
  hasNextPage,
  setHasNextPage,
  setListLoading,
  listLoading,
  sorting,
  setSorting,
  disableList,
  finish,
  setOpenInvoiceReport,
}) {
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const [user, setUser] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [openSnack, setOpenSnack] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [create, setCreate] = useState(false);
  const [filteredRows, setfilteredRows] = useState([]);
  const openModalCustomer = useRef(null);
  const [messageAlert, setMessageAlert] = useState('');
  const [dataToReconcile, setDataToReconcile] = useState([]);
  const [colorAlert, setColorAlert] = useState('warning');
  const openModalReconcile = useRef(null);
  const [archiveList, setArchiveList] = useState(false);
  const [openModalApply, setOpenModalApply] = useState(false);
  const [dataToPayments, setDataToPayments] = useState([]);
  const openApplyPayments = useRef(null);
  let archiveCustomers = [];
  const token = localStorage.token;
  const [loading, setLoading] = useState(false);
  const [openReportOptions, setOpenReportOptions] = useState(false);

  useEffect(() => {
    if (filteredRows && filteredRows.length > 0) {
      setLoading(false);
    }
  }, [filteredRows]);

  const handleClick = () => {
    setCreate(true);
    openModalCustomer.current(true);
  };

  const handleClickPaymentReport = async () => {
    setIsOpen(true);
  };

  const handleToAllReconcile = async () => {
    setLoading(true);

    const result = await client.post(
      `/invoices/customers/${userInfo?.company?.compacctnum}/reconcile-all`
    );
    setLoading(false);

    if (result?.data?.length == 0) {
      setMessageAlert(
        'No unreconciled invoices found. Please ensure you have the latest invoice data or check back later.'
      ); //TODO change message
      setOpenSnack(true);

      setTimeout(() => {
        setOpenSnack(false);
      }, 8000);
      return;
    }
    const filteredArr = result?.data.allDataOG.filter((obj) => obj.customerId !== null);
    setDataToReconcile(filteredArr);
    openModalReconcile.current(true);
  };

  const getToApply = async () => {
    setLoading(true);
    const result = await client.get(
      `/invoices/get-apply/${prop_data ? prop_data?.compacctnum : userInfo.company.compacctnum}`
    );
    setLoading(false);
    setDataToPayments(result.data);
  };

  function handleSearchText(event) {
    setSearchText(event.target.value);
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const [balance, setBalance] = useState(false);

  const handleClickOpen = () => {
    setBalance(true);
  };

  const handleClosebalance = () => {
    setBalance(false);
  };

  const messageIfNoData = () => {
    setColorAlert('warning');
    setMessageAlert('There are no invoices available to apply payments'); //TODO improve message
    setOpenSnack(true);
    setTimeout(() => {
      setOpenSnack(false);
    }, 8000);
  };

  const handleToApply = () => {
    if (dataToPayments.length > 0) {
      const data = [...dataToPayments];
      data.forEach((item) => {
        item.selectAll = false;
        item.clientSelected = false;
        if (item.payments.length > 0) {
          item.payments.forEach((item) => {
            item.select = false;
          });
        }
      });
      setDataToPayments(data);
      setOpenModalApply(true);
      return;
    }
    messageIfNoData();
  };

  const handleToApplyPerCustomer = (id) => {
    if (dataToPayments.length > 0) {
      const data = [...dataToPayments];
      data.forEach((item) => {
        if (item.clientId === id) {
          item.clientSelected = true;
          item.selectAll = true;
        } else {
          item.selectAll = false;
          item.clientSelected = false;
        }

        if (item.payments.length > 0) {
          item.payments.forEach((item) => {
            item.select = false;
          });
        }
      });
      setOpenModalApply(true);
      setDataToPayments(data);
      return;
    }
    messageIfNoData();
  };

  const handleToApplyPerInvoice = (id, invoiceId) => {
    if (dataToPayments.length > 0) {
      const data = [...dataToPayments];
      data.forEach((item) => {
        if (item.clientId === id) {
          item.clientSelected = true;
          item.selectAll = false;
        } else {
          item.selectAll = false;
          item.clientSelected = false;
        }

        if (item.payments.length > 0) {
          item.payments.forEach((item) => {
            if (item.invoiceId === invoiceId) {
              item.select = true;
            } else {
              item.select = false;
            }
          });
        }
      });
      setOpenModalApply(true);
      setDataToPayments(data);
      return;
    }
    messageIfNoData();
  };

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: <Typography>{msg}</Typography>,
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        variant: color,
      })
    );
  };

  const listArchiveCustomer = async (e) => {};

  useEffect(() => {
    const convertCurrentToNumber = (current) => {
      return Number(current.replace(/[^0-9\.]/g, ''));
    };
    if (rows?.length > 0) {
      getToApply();
    }
  }, [rows]);

  useEffect(() => {
    if (dataToPayments.length > 0) {
      openApplyPaymentsPerCustomer.current = handleToApplyPerCustomer;
      openApplyPaymentPerInvoice.current = handleToApplyPerInvoice;
      openApplyPayments.current = handleToApply;
    }
  }, [dataToPayments]);

  useEffect(async () => {
    setHasNextPage(true);
    setPage(1);
    archive ? await getArchiveCustomers(1) : await getCustomers(1);
  }, [searchText]);

  const listInnerRef = useRef();
  const [scrollX, setScrollX] = useState(0);
  const initialPageCount = rows.length > 0 ? 1 : 0;

  const buttonRef = useRef(null);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click();
    }
  };

  const tkbuttonRef = useRef(null);
  const handleTkKeyPress = (event) => {
    if (event.key === 'Enter') {
      tkbuttonRef.current.click();
    }
  };

  const getCustomers = async (page = 1) => {
    setHasGlobalTicketSearched(false);
    setGlobalResults([]);
    setGlobalSearchText('');
    setGlobalSearch(false);
    setGlobalTicketSearchText('');
    setGlobalTicketSearch(false);
    //setCustomerId(null);
    //setSelectedCustomer(null);
    setInvoiceDate1('');
    setInvoiceDate2('');
    const url = searchText.length
      ? `/invoices/customers-search/${
          prop_data ? prop_data?.compacctnum : userInfo?.company?.compacctnum
        }/${searchText}`
      : `/invoices/customers/${
          prop_data ? prop_data?.compacctnum : userInfo?.company?.compacctnum
        }`;
    const result = await client.get(url, { params: { page } });
    if (result?.status == 200 && Array.isArray(result.data)) {
      if (page != 1) {
        result.data.map((cust, index) => {
          const cust_found = filteredRows.find((customer) => {
            if (customer.customer.customers_id == cust.customer.customers_id) {
              return true;
            }
          });
          cust_found ? result.data.splice(index, 1) : null;
        });
      }
      const data = page == 1 ? result.data : [...filteredRows, ...result.data];
      if (data.length > 0) {
        setPage(page);
      }

      if (data.length == 0) {
        setHasNextPage(false);
      }
      setRows(data);
    } else if (result?.data?.msg) {
      if (filteredRows.length == 0 || page == 1) {
        setRows([]);
        setfilteredRows([]);
      }
      setHasNextPage(false);
    }
  };

  const onScroll = async (event) => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight, scrollLeft, offsetHeight } =
        listInnerRef.current;
      const allow = scrollLeft > scrollX || scrollLeft < scrollX ? false : true;
      setScrollX(scrollLeft);
      if (offsetHeight + scrollTop + 10 >= scrollHeight && allow) {
        if (hasNextPage && !listLoading) {
          /* This will be triggered after hitting the last element. API call should be made here while implementing pagination. */
          if (!hasGlobalTicketSearched) {
            archive ? await getArchiveCustomers(page + 1) : await getCustomers(page + 1);
          }
        }
      }
    }
  };

  const handleOpenReportOptionsModal = () => {
    setOpenReportOptions(true);
  };

  const handleCloseReportOptionsModal = () => {
    setOpenReportOptions(false);
  };

  // Función que se ejecuta al seleccionar una opción en el modal.
  // Se realiza la petición al backend incluyendo el formato seleccionado como parámetro.
  const handleReportOptionSelected = async (option) => {
    setOpenReportOptions(false);
    setLoading(true);

    try {
      const response = await fetch(
        `${constants.URLLOCAL}/invoices/customers/${
          prop_data ? prop_data.compacctnum : userInfo?.company?.compacctnum
        }/report?format=${encodeURIComponent(option)}`,
        {
          method: 'POST',
          headers: {
            token: `${token}`,
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        const blob = await response.blob();
        const today = moment().format('MM-DD-YYYY');
        // saveAs(
        //   blob,
        //   `${
        //     prop_data ? prop_data.compacctnum : userInfo?.company?.company_id
        //   }_Report_Not_Reconcile_Report_${today}.xlsx`
        // );

        saveAs(blob, `Unreconciled_Invoices_${option}_LOADZ_${today}.xlsx`);
      } else {
        setColorAlert('warning');
        setMessageAlert('No hay facturas sin conciliar disponibles para generar un reporte');
        setOpenSnack(true);
        setTimeout(() => {
          setOpenSnack(false);
        }, 8000);
      }
    } catch (error) {
      setLoading(false);
      setColorAlert('error');
      setMessageAlert('Error al generar el reporte');
      setOpenSnack(true);
      setTimeout(() => {
        setOpenSnack(false);
      }, 8000);
    }
  };

  const getArchiveCustomers = async (page = 1) => {
    setLoading(true);
    setListLoading(true);
    setHasGlobalTicketSearched(false);
    setGlobalResults([]);
    setGlobalSearchText('');
    setGlobalSearch(false);
    setGlobalTicketSearchText('');
    setGlobalTicketSearch(false);
    setCustomerId(null);
    setSelectedCustomer(null);
    setInvoiceDate1('');
    setInvoiceDate2('');
    const url = searchText
      ? `/customers/archive/${
          prop_data ? prop_data?.compacctnum : userInfo?.company?.compacctnum
        }/${searchText}`
      : `/customers/archive/${prop_data ? prop_data?.compacctnum : userInfo?.company?.compacctnum}`;
    const result = await client.get(url, { params: { page } });

    if (result?.data?.msg == 'No customers found') {
      if (filteredRows.length == 0 || page == 1) {
        setRows([]);
        setfilteredRows([]);
      }
      _showMessage('No archived customers found', 'warning'); //TODO improve this message
      setLoading(false);
      setListLoading(false);
      setHasNextPage(false);
      return;
    }

    result.data.map((cust, index) => {
      const cust_found = filteredRows.find(
        (customer) => customer.customer.customers_id == cust.customer.customers_id
      );
      cust_found ? result.data.splice(index, 1) : null;
    });

    const data = page == 1 ? result.data : [...filteredRows, ...result.data];
    if (data.length > 0) {
      setPage(page);
    }

    if (data.length == 0) {
      setHasNextPage(false);
    }
    setRows(data);
    setLoading(false);
    setListLoading(false);
  };

  useEffect(() => {
    if (rows.length > 0) setfilteredRows(rows);
  }, [rows]);

  useEffect(async () => {
    setRows([]);
    setfilteredRows([]);
    setPage(1);
    setHasNextPage(true);
    setInfoCustomer([]);
    if (archive) {
      await getArchiveCustomers();
    } else {
      await getCustomers();
    }
  }, [archive]);

  return (
    <div className='flex flex-col flex-auto h-full clientpage'>
      <Snackbar
        sx={{
          paddingTop: '50px',
          color: colorAlert,
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnack}
        autoHideDuration={8000}
      >
        <Alert
          onClick={() => {}}
          onClose={handleCloseSnack}
          severity={colorAlert}
          sx={{ width: '100%' }}
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <ModalToReconcileAllCustomers
        prop_data={prop_data}
        dataToReconcile={dataToReconcile}
        openModalReconcile={openModalReconcile}
        setOpenSnack={setOpenSnack}
        setMessageAlert={setMessageAlert}
        constants={constants}
        setColorAlert={setColorAlert}
        rechargedAfterChanges={rechargedAfterChanges}
      />

      <ModalPaymentReport
        prop_data={prop_data}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></ModalPaymentReport>

      <ModalToEditCustomers
        prop_data={prop_data}
        refreshCustomers={refreshCustomers}
        id={null}
        setOpen={openModalCustomer}
        rechargedAfterChanges={rechargedAfterChanges}
        create={create}
        setSelectedCustomer={setSelectedCustomer}
        setCustomerId={setCustomerId}
      />

      <ModalToApplyPayments
        prop_data={prop_data}
        open={openModalApply}
        setOpen={setOpenModalApply}
        dataToPayments={dataToPayments}
        setDataToPayments={setDataToPayments}
        customerId={customerId}
        rechargedAfterChanges={rechargedAfterChanges}
        setMessage={setMessage}
        showInvoiceInApply={showInvoiceInApply}
        setShowInvoiceInApply={setShowInvoiceInApply}
        tickets={tickets}
        setTickets={setTickets}
        setInfo={setInfo}
        info={info}
        openModalViewInvoice={openModalViewInvoice}
        isSubContractor={false}
        labelToSelect={'Customers'}
      />

      <ModalReportOptions
        open={openReportOptions}
        onClose={handleCloseReportOptionsModal}
        onSelectOption={handleReportOptionSelected}
      />

      <AppBar position='static' color='default' elevation={0}>
        <Toolbar className='px-16'>
          <Paper className='flex p-4 items-center w-full px-8 py-4 shadow'>
            <Icon color='action'>search</Icon>

            <Input
              placeholder='Search Customers'
              className='flex flex-1 px-8'
              disableUnderline
              fullWidth
              value={searchText}
              inputProps={{
                'aria-label': 'Search',
              }}
              onChange={handleSearchText}
            />
          </Paper>
        </Toolbar>
      </AppBar>
      {/* <FuseScrollbars className="overflow-y-auto flex-1"> */}
      <List ref={listInnerRef} onScroll={onScroll} className='w-full overflow-y-auto flex-1'>
        {useMemo(() => {
          const container = {
            show: {
              transition: {
                staggerChildren: 0.1,
              },
            },
          };

          const item = {
            hidden: { opacity: 0, y: 20 },
            show: { opacity: 1, y: 0 },
          };

          if (finish == false) {
            return <FuseLoading />;
          }

          return (
            <motion.div
              className='flex flex-col flex-shrink-0 w-full px-10'
              variants={container}
              initial='hidden'
              animate='show'
            >
              <motion.div variants={item} className='flex flex-col'>
                <ListItemText
                  sx={{
                    textAlign: 'start',
                  }}
                >
                  <Tooltip title='Reconcile Invoices' placement='top' style={{ flex: 'none' }}>
                    <IconButton onClick={() => handleToAllReconcile()}>
                      <FactCheckIcon sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title='Customer Balance & Aging Summary'
                    placement='top'
                    style={{ flex: 'none' }}
                  >
                    <IconButton onClick={handleClickOpen}>
                      <Paid sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Apply Payments' placement='top' style={{ flex: 'none' }}>
                    <IconButton onClick={() => openApplyPayments.current()}>
                      <PaymentsIcon sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title='Download Pending Reconcile Report'
                    placement='top'
                    style={{ flex: 'none' }}
                  >
                    {/* <IconButton onClick={() => handleClickReport()}>
                      <FileDownloadIcon sx={{ color: grey[900] }} />
                    </IconButton> */}
                    <IconButton onClick={handleOpenReportOptionsModal}>
                      <FileDownloadIcon sx={{ color: 'grey.900' }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Create Customer' placement='top' style={{ flex: 'none' }}>
                    <IconButton onClick={() => handleClick()}>
                      <AddCircleIcon sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title='Credit & Bank Transfer Settlement Report'
                    placement='top'
                    style={{ flex: 'none' }}
                  >
                    <IconButton onClick={() => handleClickPaymentReport()}>
                      <img width='25px' height='25px' src='/assets/images/logos/fp.png'></img>
                      {/* <img src="https://app.loadz.com/assets/images/logos/fp.png"></img> */}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title=' Archived Customers' placement='top' style={{ flex: 'none' }}>
                    <IconButton
                      onClick={() => {
                        setArchive(!archive);
                      }}
                    >
                      {archive ? (
                        <UnarchiveIcon sx={{ color: grey[900] }} />
                      ) : (
                        <ArchiveIcon sx={{ color: grey[900] }} />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Global Invoice Search' placement='top' style={{ flex: 'none' }}>
                    <IconButton
                      onClick={() => {
                        if (!globalSearch == false) {
                          _showMessage('Global Invoice Search OFF', 'info');
                        } else {
                          _showMessage('Global Invoice Search ON', 'info');
                        }
                        setGlobalSearch(!globalSearch);
                        setGlobalSearchText('');
                        setGlobalTicketSearch(false);
                        setGlobalTicketSearchText('');
                        if (hasGlobalTicketSearched == true) {
                          refreshCustomers();
                          setHasGlobalTicketSearched(false);
                          setGlobalResults([]);
                        }
                      }}
                    >
                      <TravelExploreIcon sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip
                    title='Global Invoiced Ticket Search'
                    placement='top'
                    style={{ flex: 'none' }}
                  >
                    <IconButton
                      onClick={() => {
                        if (!globalTicketSearch == false) {
                          _showMessage('Global Invoiced Ticket Search OFF', 'info');
                        } else {
                          _showMessage('Global Invoiced Ticket Search ON', 'info');
                        }
                        setGlobalTicketSearch(!globalTicketSearch);
                        setGlobalTicketSearchText('');
                        setGlobalSearch(false);
                        setGlobalSearchText('');
                        if (hasGlobalTicketSearched == true) {
                          refreshCustomers();
                          setHasGlobalTicketSearched(false);
                          setGlobalResults([]);
                        }
                      }}
                    >
                      <ManageSearchIcon sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Invoice Report' placement='top' style={{ flex: 'none' }}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenInvoiceReport(true);
                      }}
                    >
                      <AssessmentIcon sx={{ color: grey[900] }} />
                    </IconButton>
                  </Tooltip>
                </ListItemText>
                {globalSearch == true ? (
                  <div className='flex flex-row items-center justify-center'>
                    <Input
                      value={globalSearchText}
                      style={{ height: '100%', color: 'black' }}
                      className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/2'
                      disableUnderline
                      fullWidth
                      type='number'
                      placeholder='Global Invoice Search'
                      onChange={(e) => {
                        if (RegExp('[0-9]').test(e.target.value.slice(-1)) == false) {
                          e.target.value = e.target.value.slice(0, -1);
                        }
                        setGlobalSearchText(e.target.value.replace(/\s/g, ''));
                      }}
                      onKeyPress={handleKeyPress}
                    />
                    <Tooltip title='Search' placement='right' style={{ flex: 'none' }}>
                      <IconButton
                        onClick={() => {
                          getCustomerFromInvoice();
                        }}
                        ref={buttonRef}
                      >
                        <SearchIcon sx={{ color: grey[900] }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
                {globalTicketSearch == true ? (
                  <div className='flex flex-row items-center justify-center'>
                    <Input
                      value={globalTicketSearchText}
                      style={{ height: '100%', color: 'black' }}
                      className='flex ml-8 rounded-4 shadow ticket-search px-8 py-4 w-full md:w-1/2'
                      disableUnderline
                      fullWidth
                      type='number'
                      placeholder='Global Invoiced Ticket Search'
                      onChange={(e) => {
                        if (RegExp('[0-9]').test(e.target.value.slice(-1)) == false) {
                          e.target.value = e.target.value.slice(0, -1);
                        }
                        setGlobalTicketSearchText(e.target.value.replace(/\s/g, ''));
                        if (e.target.value.length == 0 && hasGlobalTicketSearched == true) {
                          refreshCustomers();
                          setHasGlobalTicketSearched(false);
                          setGlobalResults([]);
                        }
                      }}
                      onKeyPress={handleTkKeyPress}
                    />
                    <Tooltip title='Search' placement='right' style={{ flex: 'none' }}>
                      <IconButton
                        onClick={() => {
                          setCustomerId(null);
                          setSelectedCustomer(null);
                          setInfoCustomer([]);
                          getCustomerFromInvoiceTicket();
                          setHasGlobalTicketSearchedLoading(true);
                        }}
                        ref={tkbuttonRef}
                      >
                        <SearchIcon sx={{ color: grey[900] }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
              </motion.div>

              {hasGlobalTicketSearchedLoading == true || listLoading == true ? (
                <FuseLoading />
              ) : (
                Array.isArray(filteredRows) &&
                filteredRows.length > 0 &&
                filteredRows?.map((row, i) => (
                  <motion.div
                    style={{ backgroundColor: `${disableList == true ? 'lightgray' : 'white'}` }}
                    variants={item}
                    key={i}
                    onClick={() => {
                      if (disableList == false) {
                        setSorting({
                          invoice: { active: false, order: 'asc' },
                          job: { active: false, order: 'asc' },
                          dateUpdated: { active: false, order: 'asc' },
                          dueDate: { active: false, order: 'asc' },
                          totalHauling: { active: false, order: 'asc' },
                          totalWait: { active: false, order: 'asc' },
                          material: { active: false, order: 'asc' },
                          grandTotal: { active: false, order: 'asc' },
                          balancePending: { active: false, order: 'asc' },
                          agent: { active: false, order: 'asc' },
                          current: { column: '', order: '' },
                          flag: false,
                        });

                        const { customer } = row;
                        const { customers_id } = customer;

                        setJobNo('');
                        setInvoiceNo('');
                        setPaymentSearch('');
                        setDate2('');
                        setDate1('');
                        setInvoiceDate1('');
                        setInvoiceDate2('');
                        setCustomerId(customers_id);
                        setSelectedCustomer(customer);
                        if (!hasGlobalTicketSearched) {
                          setTabValue(0);
                        }
                        if (!hasGlobalTicketSearched) {
                          //setInfoCustomer([]);
                        }
                      }
                    }}
                  >
                    <CustomersList
                      prop_data={prop_data}
                      clients={row.customer}
                      setArchiveList={setArchiveList}
                      archive={archive}
                      setArchive={setArchive}
                      avatar={row.logo}
                      handleDrawerToggle={handleDrawerToggle}
                      listArchiveCustomer={listArchiveCustomer}
                    />
                  </motion.div>
                ))
              )}
            </motion.div>
          );
        }, [
          contacts,
          user,
          searchText,
          dispatch,
          isMobile,
          filteredRows,
          archive,
          prop_data,
          globalSearchText,
          globalSearch,
          globalTicketSearch,
          globalTicketSearchText,
          hasGlobalTicketSearched,
          hasGlobalTicketSearchedLoading,
          listLoading,
          disableList,
          finish,
        ])}
      </List>

      <ModelBalance open={balance} onClose={handleClosebalance} />
      {/* </FuseScrollbars> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.loadz_auth?.AuthReducer?.user,
  };
};

export default connect(mapStateToProps, null)(Customers);
