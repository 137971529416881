import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { formatter } from '../../../../constants';
import { Button, Hidden, Tooltip } from '@mui/material';
import client from '../../../../client';

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  ...(active && {
    backgroundColor: theme.palette.background.paper,
  }),
}));

function CustomersList({
  clients,
  avatar,
  handleDrawerToggle,
  listArchiveCustomer,
  setArchiveList,
  setArchive,
  archive,
  prop_data,
}) {
  const handleunarchive = async (customerId) => {
    try {
      const result = await client.get(`/customers/unarchive-customer/${customerId}`);
      listArchiveCustomer();
      setArchiveList(false);
      setArchive(!archive);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Hidden mdUp>
        <StyledListItem
          button
          className='px-16 py-12 min-h-92'
          active={0}
          // onClick={() => {
          //   console.log(clients)
          // }}
          onClick={() => handleDrawerToggle()}
        >
          <div className='relative'>
            <div className='absolute right-0 bottom-0 -m-4 z-10'>
              {/* <StatusIcon status={props.contact.status} /> */}
            </div>

            <Avatar src={avatar} alt={'avater'}>
              {avatar ? null : clients?.customer_name?.charAt(0)}
            </Avatar>
          </div>

          <ListItemText
            classes={{
              root: 'min-w-px px-16',
              primary: 'font-medium text-14',
              secondary: 'truncate',
            }}
            primary={clients?.customer_name ? clients.customer_name : 'No Name'}
            secondary={
              <div className='flex flex-col'>
                <span>{`Balance: ${formatter.format(
                  clients?.balanceTotal ? clients.balanceTotal : 0
                )}`}</span>
                {clients?.bol_pending > 0 ? (
                  <span>{`(${clients?.bol_pending}) BOL Pending`}</span>
                ) : null}
              </div>
            }
          />

          {/* <div className="flex flex-col justify-center items-end">
        <Typography>sbhdbshdbshb</Typography>

      </div> */}
        </StyledListItem>
      </Hidden>
      <Hidden mdDown>
        <StyledListItem
          button
          className='px-16 py-12 min-h-92 stylelist-items'
          active={0}
          // onClick={()=>(handleDrawerToggle())}
        >
          <div className='relative'>
            <Avatar src={avatar} alt={'avater'}>
              {avatar ? null : clients?.customer_name?.charAt(0)}
            </Avatar>
          </div>

          <ListItemText
            classes={{
              root: 'min-w-px px-16',
              primary: 'font-medium text-14',
              secondary: 'truncate',
            }}
            primary={clients?.customer_name}
            // secondary={`Balanceyyy: ${formatter.format(clients.balanceTotal)}`}
            secondary={
              <div className='flex flex-col'>
                <div className='flex'>
                  <Typography
                    sx={{ display: 'inline', fontWeight: 'bold' }}
                    component='span'
                    variant='body2'
                  >
                    Balance
                  </Typography>
                  {formatter.format(clients?.balanceTotal ? clients.balanceTotal : 0)}
                </div>
                <Typography sx={{ display: 'inline' }} component='span' variant='body2'>
                  {clients?.bol_pending > 0
                    ? `(${clients?.bol_pending ? clients?.bol_pending : ''}) BOL Pending`
                    : null}
                </Typography>
              </div>
            }
          />

          <div className='relative' style={{ display: `${archive == false ? 'none' : ''}` }}>
            <Tooltip title=' Unarchive Customer' placement='top'>
              <Button
                onClick={() => {
                  handleunarchive(clients?.customers_id);
                }}
              >
                {clients?.c_visibility === false ? (
                  <div>
                    <img src='/assets/images/printer.svg' alt='' className='m-auto' />
                    <span className='icon-title'> Unarchive</span>
                  </div>
                ) : null}
              </Button>
            </Tooltip>
          </div>
        </StyledListItem>
      </Hidden>
    </>
  );
}

export default CustomersList;
