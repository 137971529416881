import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';

const TicketCreateSummary = ({ data,open,handleClose }) => {
    

    return (
        
        <Dialog open={open} /* onClose={handleClose} */ maxWidth="lg" fullWidth
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%', // Set width to 100%
                        maxHeight: '80vh', // Set max height to 80% of the viewport height
                    },
                },
            }}
        >
            <DialogTitle className='flex justify-center text-24'>Summary</DialogTitle>

            <DialogContent className='flex flex-col justify-evenly'>
                {
                    data?.succ?.length > 0 ?

                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'green'}}>
                                <TableCell sx={{color:'white',width:'80px'}}>Ticket</TableCell>
                                <TableCell sx={{color:'white'}}>Status</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.succ.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{width:'80px'}}>{row.idx+1}</TableCell>
                                    <TableCell>Ticket {row.tk_no} was successfully created</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    null
                }
                
                {
                    data?.err?.length > 0 ? 

                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'crimson'}}>
                                <TableCell sx={{color:'white',width:'80px'}}>Ticket</TableCell>
                                <TableCell sx={{color:'white'}}>Status</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {data.err.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{width:'80px'}}>{data?.succ?.length >= 1 ? row.idx+1 : index+1}</TableCell>
                                    <TableCell>{row.err_message}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    :
                    null
                }

            </DialogContent>

            <DialogContent className='flex justify-center'>
                <Button variant="contained" color="primary" onClick={handleClose}>
                    Close
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default TicketCreateSummary;

